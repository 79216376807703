/* eslint-disable @typescript-eslint/naming-convention */
export enum FETCH_STATUS {
  NO_ACTIVE_REQUEST,
  PENDING,
  SUCCESS,
  FAIL,
}

export enum LOCATION_TYPES {
  INSIDE_DC = 'INSIDE_DC',
  ROUTE = 'ROUTE',
  POINT_OF_SALE = 'POINT_OF_SALE',
  SALES_COMMISSION = 'SALES_COMMISSION',
  TRUCK = 'TRUCK',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  FACTORY = 'FACTORY',
}

export enum HEALTH_STATUS {
  STABLE = 'STABLE',
  STABLE_WITH_OXYGEN = 'STABLE_WITH_OXYGEN',
  SERIOUS_WITH_OXYGEN = 'SERIOUS_WITH_OXYGEN',
  SERIOUS_WITHOUT_OXYGEN = 'SERIOUS_WITHOUT_OXYGEN',
}

export enum DOCTOR_STATUS {
  IN_TEST = 'IN_TEST',
  QUARANTINE = 'QUARANTINE',
  DISCHARGED = 'DISCHARGED',
}

export enum COLOR_CODE_NEW {
  BLACK = '#000',
  WHITE = '#FFF',
  PRIMARY = '#C00000',
  PRIMARY_LIGHT = '#FFE6E6',
  PRIMARY_DARK = '#45120F', // usually used for headers
  DEFAULT = '#F6EFEF',
  DEFAULT_DARK = '#B18B8B', // usually used for subheaders
  COMPLEMENTARY = '#F39C1F',
  COMPLEMENTARY_LIGHT = '#FFEACC',
  COMPLEMENTARY2 = '#DA5926', // ORANGE
  DANGER = '#EF5681',
  DANGER_LIGHT = '#FFD9E3',
  SUCCESS = '#70AD47',
  SUCCESS_LIGHT = '#DDF2CE',
  DIVIDERS = '#F8E4E4',
  BORDERS = '#FBDDDD',
  INPUT_BACKGROUND = '#FFFAFA', // input backgrounds such as dropdowns
  BACKGROUND = '#FAF5F5', // 2 purposes: backgrounds and some default buttons.
}

export enum COLOR_CODE {
  PRIMARY = '#1976F7',
  SECONDARY = '#9DAECB',
  TERTIARY = '#0D2A59',
  WARNING = '#e27111',
  DANGER = '#F15382',
  SUCCESS = '#5CDFE6',
  DARK = 'black',
  LIGHT = '#AFB8C5',
  WHITE = 'white',
  LIGHT_BLUE_BG = '#F7FAFF',
  LIGHT_ALMOND_BG = '#CEF2F3',
  LIGHT_PURPLE = '#98AEFC',
  DARK_SUCCESS = '#30C1C8',
  MUTED = '#98A5B4',
  PLACEHOLDER = '#8A99AA',
  INVALID_INPUT_BORDER = '#E0EBFE',
  VALID_INPUT_BORDER = '#ECF3FE',
  UNTOUCHED_INPUT_BG = '#FAFCFF',
}

export enum MAIN_LOCATION {
  INSIDE_DC,
  OUTSIDE_DC,
}

export enum FONT_SIZES {
  LARGE_HEADER = 38,
  MEDIUM_HEADER = 32,
  SMALL_HEADER = 28,
  LARGE_TEXT = 24,
  MEDIUM_TEXT = 16,
  SMALL_TEXT = 13,
  SMALLEST_TEXT = 10,
}

export enum TICKET_TYPE {
  ACT = 'ACT',
  CONDITION = 'CONDITION',
  ENVIRONMENT = 'ENVIRONMENT',
}

export enum SECURITY_STATUS {
  SECURE = 'SECURE',
  INSECURE = 'INSECURE',
}

export enum RISK_LEVEL {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum PRIORITY {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum PAGES {
  LOCATION = 'LOCATION',
  DETAILS = 'DETAILS',
  REVIEW = 'REVIEW',
}

export enum USERNAME_TYPE {
  EMAIL,
  PIN,
}

export enum FILTER_OPTIONS {
  lastDay = 'LAST_DAY',
  lastWeek = 'LAST_WEEK',
  lastMonth = 'LAST_MONTH',
  lastHour = 'LAST_HOUR',
  customPeriod = 'CUSTOM_PERIOD',
}

export enum TimeFilterOption {
  LAST_HOUR,
  LAST_DAY,
  LAST_WEEK,
  LAST_MONTH,
  CUSTOM_PERIOD,
}

export enum USER_TYPES {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export enum UserTypes {
  CorporateSupply = 'CorporateSupply',
  Corporate = 'Corporate',
  DistributionCenterManager = 'DistributionCenterManager',
  DistributionCenterManagerSupply = 'DistributionCenterManagerSupply',
  DistributionCenterManagerDPO = 'DistributionCenterManagerDPO',
  SubzoneGeneralManager = 'SubzoneGeneralManager',
  SubzoneGeneralManagerDPO = 'SubzoneGeneralManagerDPO',
  SubzoneGeneralManagerSupply = 'SubzoneGeneralManagerSupply',
  CorporateDPO = 'CorporateDPO',
  ReporterOnly = 'ReporterOnly',
  ReporterOnlySupply = 'ReporterOnlySupply',
  ReporterOnlyDPO = 'ReporterOnlyDPO',
  CertificatesOnly = 'CertificatesOnly',
  LocationSecurity = 'LocationSecurity',
  LocationManager = 'LocationManager',
  LocationSecurityDoctor = 'LocationSecurityDoctor',
  BackendEdit = 'BackendEdit',
  BreweryViewer = 'BreweryViewer',
  BreweryPlanner = 'BreweryPlanner',
  BreweryViewerManager = 'BreweryViewerManager',
  Doctor = 'Doctor',
  DoctorReporterOnly = 'DoctorReporterOnly',
  DoctorReporterOnlySupply = 'DoctorReporterOnlySupply',
}

export enum BLOCKED_REASONS {
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  MANUAL = 'MANUAL',
  VISIT_DOCTOR = 'VISIT_DOCTOR',
}

export enum TRANSLATIONS {
  /** an _ABB suffix added to a word indicates an abbreviation */
  WORKS_AT = 'Works at',
  APPLY_OWDS = 'Apply OWDs',
  LOADING_FILE = 'Loading file',
  OWD = 'OWDs',
  PDT = 'PDT',
  OVER_WRITE_FILE = 'Overwrite File',
  LAST_LOAD = 'LAST_LOAD',
  TEMPLATE = 'TEMPLATE',
  DOWNLOAD_TEMPLATE = 'Download Template',
  DOWNLOAD_FILE = 'Download File',
  ARCHIVO = 'Archivo',
  TYPE_OF_COLLABORATOR = 'Type of Collaborator',
  SELECT_DISTRIBUTION_PARAGRAPH = 'In order to access the configuration of the OWDs it is necessary to select a Distribution Center using the top bar',
  SELECT_DISTRIBUTION_5S_PARAGRAPH = 'In order to access the 5s configuration it is necessary to select a Distribution Center using the top bar',
  WITHIN_24_HOURS = 'Dentro de 24 horas',
  MORE_24_HOURS = 'Fuera de 24 horas',
  week1 = '1er sem',
  week2 = '2da sem',
  week3 = '3ra sem',
  week4 = '4ta sem',
  week5 = '5ta sem',
  week6 = '6ta sem',
  week7 = '7ma sem',
  week8 = '8ma sem',
  week9 = '9ma sem',
  week10 = '10ma sem',
  week11 = 'sem 11',
  week12 = 'sem 12',
  week13 = 'sem 13',
  week14 = 'sem 14',
  week15 = 'sem 15',
  week16 = 'sem 16',
  week17 = 'sem 17',
  week18 = 'sem 18',
  week19 = 'sem 19',
  week20 = 'sem 20',
  week21 = 'sem 21',
  week22 = 'sem 22',
  week23 = 'sem 23',
  week24 = 'sem 24',
  week25 = 'sem 25',
  week26 = 'sem 26',
  week27 = 'sem 27',
  week28 = 'sem 28',
  week29 = 'sem 29',
  week30 = 'sem 30',
  week31 = 'sem 31',
  week32 = 'sem 32',
  week33 = 'sem 33',
  week34 = 'sem 34',
  week35 = 'sem 35',
  week36 = 'sem 36',
  week37 = 'sem 37',
  week38 = 'sem 38',
  week39 = 'sem 39',
  week40 = 'sem 40',
  week41 = 'sem 41',
  week42 = 'sem 42',
  week43 = 'sem 43',
  week44 = 'sem 44',
  week45 = 'sem 45',
  week46 = 'sem 46',
  week47 = 'sem 47',
  week48 = 'sem 48',
  week49 = 'sem 49',
  week50 = 'sem 50',
  week51 = 'sem 51',
  week52 = 'sem 52',
  week53 = 'sem 53',
  week54 = 'sem 54',
  INFORMATION_NOT_AVAILABLE = 'INFORMATION_NOT_AVAILABLE',
  SELECT_DISTRIBUTION_CENTER = 'Select a Distribution Center',
  GENERAL = 'General',
  CHOOSE = 'CHOOSE',
  REFERNCIA = 'REFERNCIA',
  WELCOME_TO = 'WELCOME_TO',
  DATE_INCIDENT = 'DATE_INCIDENT',
  RECORD_INCIDENT = 'RECORD_INCIDENT',
  VALIDITY = 'VALIDITY',
  CAUSE = 'CAUSE',
  SUB_CAUSE = 'SUB_CAUSE',
  NATURE = 'NATURE',
  CREATE_YOUR_PASSWORD_TO_ACCESS = 'CREATE_YOUR_PASSWORD_TO_ACCESS',
  FORGOT_YOUR_PASSWORD = 'FORGOT_YOUR_PASSWORD',
  I_REMEMBER_MY_PASSWORD = 'I_REMEMBER_MY_PASSWORD',
  RECOVER_IT_HERE = 'RECOVER_IT_HERE',
  CREATE_AN_ACCOUNT = 'CREATE_AN_ACCOUNT',
  DONT_HAVE_AN_ACCOUNT = 'DONT_HAVE_AN_ACCOUNT',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  REGISTER_ON = 'REGISTER_ON',
  REGISTER = 'REGISTER',
  LETS_FIND_YOUR_ACC = 'LETS_FIND_YOUR_ACC',
  TYPE_YOUR_EMAIL = 'TYPE_YOUR_EMAIL',
  SEND_ME_THE_LINK = 'SEND_ME_THE_LINK',
  EMAIL_SENT = 'EMAIL_SENT',
  YOU_WILL_RECEIVE_AN_EMAIL = 'YOU_WILL_RECEIVE_AN_EMAIL',
  TO_RESET_PASSWORD = 'TO_RESET_PASSWORD',
  PASSWORD = 'PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  ALREADY_HAVE_AN_ACCOUNT = 'ALREADY_HAVE_AN_ACCOUNT',
  LOGIN = 'LOGIN',
  GO_TO_LOGIN = 'GO_TO_LOGIN',
  EMAIL = 'EMAIL',
  LOADING = 'LOADING',
  YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED = 'YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED',
  YOU_CAN_START_USING_THE_APP = 'YOU_CAN_START_USING_THE_APP',
  OPEN_APP = 'OPEN_APP',
  CONTACT = 'CONTACT',
  THIS_URL_HAS_EXPIRED = 'THIS_URL_HAS_EXPIRED',
  LOGIN_TO_ACCESS_SAFETY_DASHBOARD = 'LOGIN_TO_ACCESS_SAFETY_DASHBOARD',
  LOGGING_IN = 'LOGGING_IN',
  NAME = 'NAME',
  FIRST_SURNAME = 'FIRST_SURNAME',
  SECOND_SURNAME = 'SECOND_SURNAME',
  OPTIONAL = 'OPTIONAL',
  BLOCKED_REASON = 'BLOCKED_REASON',
  SEARCH = 'SEARCH',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  CD_SETTINGS = 'CD_SETTINGS',
  BACK_TO = 'BACK_TO',
  SETTINGS = 'SETTINGS',
  EXIT = 'EXIT',
  WELCOME = 'WELCOME',
  YOU_ARE_VIEWING = 'YOU_ARE_VIEWING',
  REPORTS = 'REPORTS',
  TOTAL_REPORTS = 'TOTAL_REPORTS',
  UNIQUE = 'UNIQUE',
  UNIQUE_USERS = 'UNIQUE_USERS',
  AVERAGE_PER_USER = 'AVERAGE_PER_USER',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  BLOCK_UNBLOCK = 'BLOCK_UNBLOCK',
  AVERAGE = 'AVERAGE',
  PRECIDING_PERIOD = 'PRECIDING_PERIOD',
  REPORT_HISTORY = 'REPORT_HISTORY',
  AVERAGE_DAILY_PER_USER = 'AVERAGE_DAILY_PER_USER',
  WHERE_ARE_YOU = 'WHERE_ARE_YOU',
  ZONE = 'ZONE',
  AGENCIA_DC = 'AGENCIA_DC',
  CONTINUE = 'CONTINUE',
  NEW_REPORT = 'NEW_REPORT',
  REPORT_DATA = 'REPORT_DATA',
  WHO_IS_REPORTING_IT = 'WHO_IS_REPORTING_IT',
  WHO_COMMITTED_THE_ACT = 'WHO_COMMITTED_THE_ACT',
  IT_WAS_NOT_POSSIBLE_TO_IDENTIFY = 'IT_WAS_NOT_POSSIBLE_TO_IDENTIFY',
  IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT = 'IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT',
  IT_IS_AN_ANONYMOUS_REPORT = 'IT_IS_AN_ANONYMOUS_REPORT',
  I_COULDNT_IDENTIFY_THE_PERSON = 'I_COULDNT_IDENTIFY_THE_PERSON',
  SEARCH_THE_LIST_AGAIN = 'SEARCH_THE_LIST_AGAIN',
  WHERE_DID_YOU_SEE = 'WHERE_DID_YOU_SEE',
  CD_AREA = 'CD_AREA',
  IN_WHICH_AREA_OF_DISTRIBUTION_CENTER = 'IN_WHICH_AREA_OF_DISTRIBUTION_CENTER',
  SPECIFY_THE_PLACE_IN_THE_SUBAREA = 'SPECIFY_THE_PLACE_IN_THE_SUBAREA',
  TYPE_OF_RISK = 'TYPE_OF_RISK',
  RISK_GROUP = 'RISK_GROUP',
  WHAT_RISK_TYPE_WAS_PREVENTED = 'WHAT_RISK_TYPE_WAS_PREVENTED',
  WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT = 'WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT',
  WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION = 'WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION',
  BEHAVIOR = 'BEHAVIOR',
  WHO_HAD_THIS_BEHAVIOR = 'WHO_HAD_THIS_BEHAVIOR',
  WHO_HAD_THIS_CONDITION = 'WHO_HAD_THIS_CONDITION',
  WHO_SUBMITTED_THIS_BEHAVIOR = 'WHO_SUBMITTED_THIS_BEHAVIOR',
  WHO_SUBMITTED_THIS_CONDITION = 'WHO_SUBMITTED_THIS_CONDITION',
  WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK = 'WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK',
  BACK_TO_SEARCH_BAR = 'BACK_TO_SEARCH_BAR',
  SELECT_RISK_TYPE = 'SELECT_RISK_TYPE',
  SELECT_RISK_GROUP = 'SELECT_RISK_GROUP',
  DESCRIBE_THE_BEHAVIOR = 'DESCRIBE_THE_BEHAVIOR',
  PIN_CREATED_SUCCESSFULLY = 'PIN_CREATED_SUCCESSFULLY',
  DESCRIPTION = 'DESCRIPTION',
  OF_THE_ACT = 'OF_THE_ACT',
  OF_THE_CONDITION = 'OF_THE_CONDITION',
  RISK_LEVEL_TRANSLATION = 'RISK_LEVEL_TRANSLATION',
  CORRECTIVE_ACTION = 'CORRECTIVE_ACTION',
  UPLOAD_REGISTRATION = 'UPLOAD_REGISTRATION',
  UPLOAD_EVIDENCE = 'UPLOAD_EVIDENCE',
  ACI_OVERVIEW = 'ACI_OVERVIEW',
  LEVEL = 'LEVEL',
  WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE = 'WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE',
  FINISH_AND_SEND = 'FINISH_AND_SEND',
  THANK_YOU = 'THANK_YOU',
  SENT_SUCCESSFULLY = 'SENT_SUCCESSFULLY',
  SIO_SUCCESSFULLY_SENT = 'SIO_SUCCESSFULLY_SENT',
  THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS = 'THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS',
  SUCCESSFUL_REGISTRATION = 'SUCCESSFUL_REGISTRATION',
  YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY = 'YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY',
  CERTIFICATES = 'CERTIFICATES',
  CERTIFICATES_ONLY = 'CERTIFICATES_ONLY',
  CLOSE = 'CLOSE',
  REPORT = 'REPORT',
  ASSIGN = 'ASSIGN',
  BOTH = 'BOTH',
  DOWNLOAD = 'DOWNLOAD',
  DOWNLOAD_ALL = 'DOWNLOAD_ALL',
  INCIDENTS = 'INCIDENTS',
  INCIDENTS_PER_POSITION = 'INCIDENTS_PER_POSITION',
  ALL_ZONES = 'ALL_ZONES',
  DRVS = 'DRVS',
  DISTRIBUTION_CENTERS = 'DISTRIBUTION_CENTERS',
  DECEMBER = 'DECEMBER',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBOER',
  SEPTEMBER = 'SEPTEMBER',
  AUGUST = 'AUGUST',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MAY = 'MAY',
  APRIL = 'APRIL',
  MARCH = 'MARCH',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  ALL_MONTHLY_OPTIONS = 'ALL_MONTHLY_OPTIONS',
  // external users module - start
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  SEARCH_USERS = 'SEARCH_USERS',
  ADD_USER_TO_DC = 'ADD_USER_TO_DC',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  DOES_USER_ACCOUNT = 'DOES_USER_ACCOUNT',
  YES_HE_HAS = 'YES_HE_HAS',
  ENTER_NAME = 'ENTER_NAME',
  ENTER_EMAIL = 'ENTER_EMAIL',
  NO_CREATE_SCRATCH = 'NO_CREATE_SCRATCH',
  POSITION = 'POSITION',
  JOB_TITLE = 'Job Title',
  PERMISSIONS = 'PERMISSIONS',
  ACIS = 'ACIS', // careful, reports is also there
  ROUTINES = 'ROUTINES',
  TRAININGS = 'TRAININGS',
  ACCIDENTABILITY = 'ACCIDENTABILITY',
  ANALYTICS = 'ANALYTICS',
  CANCEL = 'CANCEL',
  ADD = 'ADD',
  ACCESS = 'ACCESS',
  EDIT_INFORMATION = 'EDIT_INFORMATION',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_OPTION_MENU = 'DELETE_USER_OPTION_MENU',
  LOGOUT = 'LOGOUT',
  GO_TO_DASHBOARD = 'GO_TO_DASHBOARD',
  CONFIRM = 'CONFIRM',
  YES = 'YES',
  NO = 'NO',
  USERS = 'USERS',
  CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
  ASK_USER_WHICH_EMAIL = 'ASK_USER_WHICH_EMAIL',
  SOMETHING_WENT_WRONG_TRY_LATER = 'SOMETHING_WENT_WRONG_TRY_LATER',
  USER_NOT_EXIST_CREATE_NEW = 'USER_NOT_EXIST_CREATE_NEW',
  DELIVERY_MAN = 'DELIVERY_MAN',
  SUPPLIER = 'SUPPLIER',
  WAREHOUSE_ASSISTANT = 'WAREHOUSE_ASSISTANT',
  PEOPLE_AND_MANAGEMENT = 'PEOPLE_AND_MANAGEMENT',
  FORKLIFT_DRIVER = 'FORKLIFT_DRIVER',
  // external users module - end
  WEEKLY_REPORTS = 'WEEKLY_REPORTS',
  BOXES = 'BOXES',
  WHO_COMMITTED = 'WHO_COMMITTED',
  DATE_REPORTED = 'DATE_REPORTED',
  REPORTS_LIST = 'REPORTS_LIST',
  ALL = 'ALL',
  BEHAVIORS = 'BEHAVIORS',
  CONDITIONS = 'CONDITIONS',
  DOWNLOAD_LIST = 'DOWNLOAD_LIST',
  PLACE = 'PLACE',
  TOTAL = 'TOTAL',
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  ASSIGNED = 'ASSIGNED',
  INSIDE_DC = 'INSIDE_DC',
  OUTSIDE_DC = 'OUTSIDE_DC',
  CONDITION = 'CONDITION',
  ENVIRONMENTAL_RISK = 'ENVIRONMENTAL_RISK',
  SAFETY_CERTIFICATES_T2 = 'SAFETY_CERTIFICATES_T2',
  ACT = 'ACT',
  WHERE_DID_YOU_SEE_IN_DC_AREA = 'WHERE_DID_YOU_SEE_IN_DC_AREA',
  DESCRIPTION_OF_THE_ACT = 'DESCRIPTION_OF_THE_ACT',
  DESCRIPTION_OF_THE_CONDITION = 'DESCRIPTION_OF_THE_CONDITION',
  CONFIRM_USER_DELETION = 'CONFIRM_USER_DELETION',
  REGION = 'REGION',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  SELECT_REGION = 'SELECT_REGION',
  SELECT_DC = 'SELECT_DC',
  SELECT_SUBLOCATION = 'SELECT_SUBLOCATION',
  SELECT = 'SELECT',
  SELECT_POSITION = 'SELECT_POSITION',
  LOCATION = 'LOCATION',
  THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT = 'THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT',
  WRITE_YOUR_8_DIGIT_SAP_NUMBER = 'WRITE_YOUR_8_DIGIT_SAP_NUMBER',
  WRITE_AN_8_DIGIT_SAP_NUMBER = 'WRITE_AN_8_DIGIT_SAP_NUMBER',
  SAP_SUCCESSFULLY_VALIDATED = 'SAP_SUCCESSFULLY_VALIDATED',
  TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER = 'TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER',
  PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER = 'PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER',
  IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT = 'IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT',
  CLEAR = 'CLEAR',
  OVERVIEW = 'OVERVIEW',
  DIST_CENTERS = 'DIST_CENTERS',
  HOME = 'HOME',
  BLOCK_UNBLOCK_SHORT = 'BLOCK_UNBLOCK_SHORT',
  PRECEDING_PERIOD = 'PRECEDING_PERIOD',
  PARTICIPATION = 'PARTICIPATION',
  INCIDENTS_NATIONAL = 'INCIDENTS_NATIONAL',
  DAILY_AVG_PER_USER = 'DAILY_AVG_PER_USER',
  ALL_THE_BUS = 'ALL_THE_BUS',
  All = 'ALL',
  BU = 'BU',
  BY_BU = 'BY_BU',
  BY_POSITION = 'BY_POSITION',
  BY_ROUTINES = 'BY_ROUTINES',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_NO_MATCH = 'PASSWORD_NO_MATCH',
  DO_NOT_USE_YOUR_OLD_PASSWORD = 'DO_NOT_USE_YOUR_OLD_PASSWORD',
  ROUTINES_FOLLOWUP = 'ROUTINES_FOLLOWUP',
  WEEK = 'WEEK',
  TRAININGS_PERCENTAGE = 'TRAININGS_PERCENTAGE',
  SIF_ROUTE_PERCENTAGE = 'SIF ROUTE %',
  TOTAL_SIF_ROUTES = 'TOTAL',
  COURSES = 'COURSES',
  HC_TO_BE_TRAINED = 'HC_TO_BE_TRAINED',
  TRAINED = 'TRAINED',
  IMAGE = 'IMAGE',
  USER_ACCESS_DISCLAIMER = 'USER_ACCESS_DISCLAIMER',
  USER_MISSING_DISCLAIMER = 'USER_MISSING_DISCLAIMER',
  SECURE_CONDITION_DISCLAIMER = 'SECURE_CONDITION_DISCLAIMER',
  YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT = 'YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT',
  INCIDENTS_PER_AREA = 'INCIDENTS_PER_AREA',
  RESET = 'RESET',
  SAFE = 'SAFE',
  PLANT = 'CD/Plant',
  ID = 'ID',
  SHARP = 'Sharp',
  STATUS = 'Status',
  REASON = 'Reason to block',
  ACTIONS = 'Actions',
  BLOCKED = 'Blocked',
  MEDIACAL_EXAMINATION = 'Medical examination',
  NO_DATA = 'NO_DATA',
  NO_DATA_FOUND_SO_FAR = 'NO_DATA_FOUND_SO_FAR',
  CURRENT_PROGRESS = 'CURRENT_PROGRESS',
  TOTAL_PROGRESS = 'TOTAL_PROGRESS',
  LAST = 'LAST',
  DOWNLOAD_ROUTINES_DB = 'DOWNLOAD_ROUTINES_DB',
  SEARCH_LOCATION = 'SEARCH_LOCATION',
  NO_MATCHES_FOUND = 'NO_MATCHES_FOUND',
  CONSEQUENCES = 'CONSEQUENCES',
  SELECT_SUBZONE_OR_DISTRIBUTION_CENTER = 'SELECT_SUBZONE_OR_DISTRIBUTION_CENTER',
  FILE_UPLOAD = 'FILE_UPLOAD',
  CRITICAL_CLIENTS = 'CRITICAL_CLIENTS',
  AT_HOME = 'AT_HOME',
  HOSPITAL = 'HOSPITAL',
  STABLE = 'STABLE',
  STABLE_WITH_OXYGEN = 'STABLE_WITH_OXYGEN',
  SERIOUS_WITH_OXYGEN = 'SERIOUS_WITH_OXYGEN',
  SERIOUS_WITHOUT_OXYGEN = 'SERIOUS_WITHOUT_OXYGEN',
  LAST_UPDATED_ON_MONTH_DATE_DAY = 'LAST_UPDATED_ON_MONTH_DATE_DAY',
  LAST_UPDATED_TODAY = 'LAST_UPDATED_TODAY',
  UPDATE_USER_STATUS_TODAY_MONTH_DATE = 'UPDATE_USER_STATUS_TODAY_MONTH_DATE',
  WHERE_IS_USER = 'WHERE_IS_USER',
  REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS = 'REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS',
  // ACTION LOG MEETINGS START
  DIARIA_LOGÍSTICA = 'DIARIA_LOGÍSTICA',
  RUTINA_MATUTINA_DE_REPARTO = 'RUTINA_MATUTINA_DE_REPARTO',
  RUTINA_DE_CAMBIO_DE_TURNO = 'RUTINA_DE_CAMBIO_DE_TURNO',
  RUTINA_SEMANAL_DE_ALMACÉN = 'RUTINA_SEMANAL_DE_ALMACÉN',
  RUTINA_SEMANAL_DE_DIST = 'RUTINA_SEMANAL_DE_DIST',
  RUTINA_SEMANAL_DE_PEOPLE = 'RUTINA_SEMANAL_DE_PEOPLE',
  RUTINA_SEMANAL_DE_FLOTA = 'RUTINA_SEMANAL_DE_FLOTA',
  RUTINA_SEMANAL_DE_SAFETY = 'RUTINA_SEMANAL_DE_SAFETY',
  RUTINA_SEMANAL_DE_PLANNING = 'RUTINA_SEMANAL_DE_PLANNING',
  RUTINE_SEMANAM_DE_GESTION = 'RUTINE_SEMANAM_DE_GESTION',
  // ACTION LOG MEETINGS END
  SAVE = 'SAVE',
  ADD_COMMENT = 'ADD_COMMENT',
  COMMENT_CREATED_AT = 'COMMENT_CREATED_AT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  COMMENT_SHOULD_BE_UNDER_N_CHARACTER = 'COMMENT_SHOULD_BE_UNDER_N_CHARACTER',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DELETING = 'DELETING',
  PARTICIPANT = 'PARTICIPANT',
  OWNER = 'OWNER',
  IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW = 'IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW',
  STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH = 'STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH',
  UPDATED_TODAY_AT_TIME = 'UPDATED_TODAY_AT_TIME',
  YOUR_QUARANTINE_HAS = 'YOUR_QUARANTINE_HAS',
  CONCLUDED = 'CONCLUDED',
  PHYSICAL_STATE = 'PHYSICAL_STATE',
  PILLAR = 'PILLAR',
  // PILLAR START
  SAFETY = 'SAFETY',
  MANAGEMENT = 'MANAGEMENT',
  PLANNING = 'PLANNING',
  DISTRIBUTION = 'DISTRIBUTION',
  FLEET = 'FLEET',
  STOCK = 'STOCK',
  PEOPLE = 'PEOPLE',
  WAREHOUSE = 'WAREHOUSE',
  FINANZAS = 'FINANZAS',
  COMERCIAL = 'COMERCIAL',
  SERVICIOS = 'SERVICIOS',
  // PILLAR END
  NOTE = 'NOTE',
  SEARCH_KPI = 'SEARCH_KPI',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  SEARCH_TO_ADD_USERS = 'SEARCH_TO_ADD_USERS',
  ACTION_LOG_TABLE_FOR_LOCATION_NAME = 'ACTION_LOG_TABLE_FOR_LOCATION_NAME',
  DELETE_FILTERS = 'DELETE_FILTERS',
  ALL_ROUTINES = 'ALL_ROUTINES',
  ALL_KPIS = 'ALL_KPIS',
  ALL_USERS = 'ALL_USERS',
  SELECT_THE_ROUTINE_YOU_WANT_TO_SEE = 'SELECT_THE_ROUTINE_YOU_WANT_TO_SEE',
  SELECT_THE_KPIS_YOU_WANT_TO_SEE = 'SELECT_THE_KPIS_YOU_WANT_TO_SEE',
  SELECT_THE_USERS_YOU_WANT_TO_SEE = 'SELECT_THE_USERS_YOU_WANT_TO_SEE',
  NO_DUE_DATE_SELECTED = 'NO_DUE_DATE_SELECTED',
  // File-upload
  CLICK_HERE_DROP_TO_UPLOAD = 'CLICK_HERE_DROP_TO_UPLOAD',
  DROP_IT_HERE = 'DROP_IT_HERE',
  FILE_TYPE_NOT_ACCEPTED_SORRY = 'FILE_TYPE_NOT_ACCEPTED_SORRY',
  MAXIMUM_ALLOWED_FILE_SIZE = 'MAXIMUM_ALLOWED_FILE_SIZE',
  REMOVE_FILE = 'REMOVE_FILE',
  UPLOADING = 'UPLOADING',
  UPLOAD = 'UPLOAD',
  ERROR = 'ERROR',
  RETRY = 'RETRY',
  FILE_IS_TOO_LARGE = 'FILE_IS_TOO_LARGE',
  SERVER_ERROR = 'SERVER_ERROR',
  PROCESSING_YOUR_FILE = 'PROCESSING_YOUR_FILE',
  PROCESSING_FAILED_WITH_MESSAGE = 'PROCESSING_FAILED_WITH_MESSAGE',
  CLICK_TO_VISIT = 'CLICK_TO_VISIT',
  REUPLOAD = 'REUPLOAD',
  FINISHED_FILE_PROCESSING = 'FINISHED_FILE_PROCESSING',
  DONE = 'DONE',
  PLEASE_UPLOAD_A_FILE = 'PLEASE_UPLOAD_A_FILE',
  LAST_UPDATED_ON = 'LAST_UPDATED_ON',
  // multi-select
  ALL_ITEMS_ARE_SELECTED = 'ALL_ITEMS_ARE_SELECTED',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  NO_OPTIONS = 'NO_OPTIONS',
  SELECT_ALL = 'SELECT_ALL',
  SELECT_ALL_FILTERED = 'SELECT_ALL_FILTERED',
  VIOLENCE_PREVENTION = 'VIOLENCE_PREVENTION',
  ROUTINE_COMPLIANCE_HEADING = 'ROUTINE_COMPLIANCE',
  ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE = 'ROUTINE_%',
  ROUTINE_COMPLIANCE_EXECUTED = 'EXECUTED',
  ROUTINE_COMPLIANCE_PENDING = 'PENDING',
  ROUTINE_COMPLIANCE_SEARCH = 'SEARCH_BY_GRO',
  ROUTINE_COMPLIANCE_REGION = 'COUNTRY_/_REGION',
  ROUTINE_COMPLIANCE_PLANNED = 'PLANNED',
  ROUTINE_COMPLIANCE_REAL = 'REAL',
  ROUTINE_COMPLIANCE_COMPLIANCE = 'COMPLIANCE',
  ROUTINE_COMPLIANCE_DOWNLOAD_DATA = 'DOWNLOAD_DATA',
  ROUTINE_COMPLIANCE_BU = 'PER_BU',
  ROUTINE_COMPLIANCE_RANKING = 'DC_RANKING_FOR_MAZ',
  ROUTINE_COMPLIANCE_CD = 'DC',
  TOOLKIT_HEADING = 'TOOLKIT_HEADING',
  TOOLKIT_CURRENT_MONTH = 'CURRENT_MONTH',
  TOOLKIT_PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  META = 'GOAL',
  NOTES = 'NOTES_FOR',
  COMMENT_BY = 'COMMENT_BY',
  LATEST_EDIT = 'LATEST_EDIT',
  SAVE_NOTE = 'SAVE_NOTE',
  SIF_STATISTICS = 'SIF_STATISTICS',
  USER_TYPE = 'USER_TYPE',
  USER_ROLE_TRANSLATION = 'USER_ROLE_TRANSLATION',
  //  pass recovery
  UPDATE = 'UPDATE',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  UPDATE_YOUR_PASSWORD_TO_ACCESS = 'UPDATE_YOUR_PASSWORD_TO_ACCESS',
  YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED = 'YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED',
  YOU_CAN_USE_THE_APP_AGAIN = 'YOU_CAN_USE_THE_APP_AGAIN',
  REDIRECT_TIMEOUT = 'REDIRECT_TIMEOUT',
  HERE = 'HERE',
  TELEMETRY = 'TELEMETRY',
  CURRENT_MONTH = 'CURRENT_MONTH',
  BY_CATEGORY = 'BY_CATEGORY',
  TELEMETRY_BY_BU = 'TELEMETRY_BY_BU',
  CRITICAL_SPEEDING = 'CRITICAL_SPEEDING',
  REGULAR_SPEEDING = 'REGULAR_SPEEDING',
  CURVE_SPEEDING = 'CURVE_SPEEDING',
  HARSH_TURNING = 'HARSH_TURNING',
  SEATBELT = 'SEATBELT',
  VEHICLE = 'VEHICLE',
  CLICK_HERE = 'CLICK_HERE',
  PREVENTION_CAMPAIGNS = 'PREVENTION_CAMPAIGNS',
  BEST_PRACTICES = 'BEST_PRACTICES',
  MONTHLY_TOTAL = 'MONTHLY_TOTAL',
  BOARDS = 'BOARDS',
  SAFETY_NEWS = 'SAFETY_NEWS',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  SELECT_MONTH = 'SELECT_MONTH',
  PYRAMID = 'PYRAMID',
  COUNTRY = 'COUNTRY',
  ACTION_LOGS = 'ACTION_LOGS',
  AMBIENTAL_RISKS = 'AMBIENTAL_RISKS',
  PRODUCTION_PERFORMANCE_TRACKING = 'PRODUCTION_PERFORMANCE_TRACKING',
  BY = 'BY',
  QR_CODE = 'QR_CODE',
  SEARCH_ROUTINE = 'SEARCH_ROUTINE',
  MANAGEMENT_TOOLS = 'MANAGEMENT_TOOLS',
  SIF_ROUTE = 'SIF_ROUTE',
  SIF_COMPLANICE = 'SIF_COMPLAINCE',
  SAFETY_TRAINING = 'SAFETY_TRAINING',
  INCORRECT_EMAIL_PASSWORD = 'INCORRECT EMAIL / PASSWORD',
  INVALID_CREDENTIALS_TRY_AGAIN = 'INVALID_CREDENTIALS_TRY_AGAIN',
  THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG = 'THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG',
  THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET = 'THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET',
  ADD_ACTION_LOG = 'ADD_ACTION_LOG',
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  COMPLETED = 'COMPLETED',
  WRITE_ACTION_HERE = 'WRITE_ACTION_HERE',
  SUCCESS_CRITERIA = 'SUCCESS_CRITERIA',
  WRITE_SUCCESS_CRITERIA_HERE = 'WRITE_SUCCESS_CRITERIA_HERE',
  REVIEW_IN = 'REVIEW_IN',
  SAVE_ACTION_LOG = 'SAVE_ACTION_LOG',
  CREATE_ACTION_LOG = 'CREATE_ACTION_LOG',
  SHOULD_BE_CLOSED_TODAY = 'SHOULD_BE_CLOSED_TODAY',
  SHOULD_HAVE_BEEN_CLOSED = 'SHOULD_HAVE_BEEN_CLOSED',
  DAY = 'DAY',
  DAYS = 'DAYS',
  TO_BE_CLOSED = 'TO_BE_CLOSED',
  THERE_ARE_NO_COMMENTS_YET = 'THERE_ARE_NO_COMMENTS_YET',
  CREATED = 'CREATED',
  DUE_DATE = 'DUE_DATE',
  SINCE = 'SINCE',
  IN = 'IN',
  WRITE_A_NOTE = 'WRITE_A_NOTE',
  WAIT_A_MOMENT = 'WAIT_A_MOMENT',
  IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE = 'IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE',
  LEAVE_WITHOUT_SAVE = 'LEAVE_WITHOUT_SAVE',
  DELETE_CARD = 'DELETE_CARD',
  NOT_ENOUGH_PERMISSIONS_TO_ACCESS = 'NOT_ENOUGH_PERMISSIONS_TO_ACCESS',
  INDICATORS = 'INDICATORS',
  DVR_CENTRAL_BAJIO = 'DVR_CENTRAL_BAJIO',
  METROPOLITAN_SBU = 'METROPOLITAN_SBU',
  METROPOLITAN = 'METROPOLITAN',
  CD_SAN_PABLO = 'CD_SAN_PABLO',
  WEB_ACCESS = 'WEB_ACCESS',
  DELIVERY_DRIVER = 'DELIVERY_DRIVER',
  FORKLIFTERS = 'FORKLIFTERS',
  SKAP = 'SKAP',
  SORRY_THERE_WAS_AN_ERROR = 'SORRY_THERE_WAS_AN_ERROR',
  RELOAD_PAGE = 'RELOAD_PAGE',
  IMAPACTED_HC = 'IMAPACTED_HC',
  FUNCTIONAL_INDUCTION = 'FUNCTIONAL_INDUCTION',
  TECHNICAL_SKILLS = 'TECHNICAL_SKILLS',
  AUTONOMOUS_SKILLS = 'AUTONOMOUS_SKILLS',
  FUNCTIONAL = 'Funcional',
  TECHNICAL = 'Technical',
  AUTONOMOUS = 'Autonomous',
  AUTONOMY_LEVEL = 'AUTONOMY_LEVEL',
  HEAD_COUNT = 'HEAD_COUNT',
  RAMP_UP = 'RAMP_UP',
  WAREHOUSE_AUXILIAR = 'WAREHOUSE_AUXILIAR',
  DELIVERY_AUXILIAR = 'DELIVERY_AUXILIAR',
  AREA = 'AREA',
  HC_TOTAL = 'HC_TOTAL',
  DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
  ALL_THE_TEAM = 'ALL_THE_TEAM',
  NEW_COLLABORATORS = 'NEW_COLLABORATORS',
  OLD_COLLABORATORS = 'OLD_COLLABORATORS',
  DELAY_TASK = 'DELAY_TASK',
  IN_WAREHOUSE = 'IN_WAREHOUSE',
  IN_FACTORY = 'IN_FACTORY',
  CONDITION_WAS_CLOSED_IMMEDIATELY = 'CONDITION_WAS_CLOSED_IMMEDIATELY',
  COULD_IT_BE_SIF = 'COULD_IT_BE_SIF',
  SIF_PRECURSOR = 'SIF_PRECURSOR',
  SIF_POTENTIAL = 'SIF_POTENTIAL',
  DEPARTMENT = 'DEPARTMENT',
  SAP_SHARP = 'SAP_SHARP',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS = 'YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS',
  YOU_MUST_ENTER_YOUR_OPERATION_TYPE = 'YOU_MUST_ENTER_YOUR_OPERATION_TYPE',
  FOLLOW_THE_INSTRUCTIONS_RECEIVED = 'FOLLOW_THE_INSTRUCTIONS_RECEIVED',
  TOO_SHORT = 'TOO_SHORT',
  ENTER_A_CORRECT_EMAIL_FORMAT = 'ENTER_A_CORRECT_EMAIL_FORMAT',
  OPERATION = 'OPERATION',
  CATEGORY = 'CATEGORY',
  PROCEDURE = 'PROCEDURE',
  MEDIUM = 'MEDIUM',
  PRIORITY_T = 'PRIORITY_T',
  LOGISTICS = 'LOGISTICS',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  FACTORY = 'FACTORY',
  AN_ERROR_HAS_OCCURRED = 'AN_ERROR_HAS_OCCURRED',
  FILE_UPLOADED_SUCCESSFULLY = 'FILE_UPLOADED_SUCCESSFULLY',
  COPY_QR_CODE = 'COPY_QR_CODE',
  SUBAREA = 'SUBAREA',
  NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE = 'NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE',
  YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES = 'YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES',
  USER_UPDATED_SUCCESSFULLY = 'USER_UPDATED_SUCCESSFULLY',
  UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE = 'UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE',
  SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE = 'SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE',
  SELECT_LOCATION = 'SELECT_LOCATION',
  OPERATIONAL = 'OPERATIONAL',
  CORPORATE = 'CORPORATE',
  DOWNLOAD_DATA = 'DOWNLOAD_DATA',
  THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED = 'THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED',
  SELECT_DOCUMENT = 'SELECT_DOCUMENT',
  SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD = 'SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD',
  DOWNLOADING_DATA = 'DOWNLOADING_DATA',
  CHOOSE_HERE = 'CHOOSE_HERE',
  WITHOUT_POSITION = 'WITHOUT_POSITION',
  RETRY_AGAIN = 'RETRY_AGAIN',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE = 'DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE',
  THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE = 'THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE',
  UNBLOCKED = 'UNBLOCKED',
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  TOTAL_PAGES = 'TOTAL_PAGES',
  REMOVE_FILTERS = 'REMOVE_FILTERS',
  UPLOAD_TEMPLATE = 'UPLOAD_TEMPLATE',
  ADD_NEW_USER = 'ADD_NEW_USER',
  DONT_FORGET_TO_SET_AN_ACCESS_TO_LOCATION = 'DONT_FORGET_TO_SET_AN_ACCESS_TO_LOCATION',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  ERROR_TO_SAVE_CHANGES_THE_USER_NEEDS_THE_ACCESS_TO_BE_SAME_AS_WORKS_AT_SELECTED = 'ERROR_TO_SAVE_CHANGES_THE_USER_NEEDS_THE_ACCESS_TO_BE_SAME_AS_WORKS_AT_SELECTED',
  USER_DISABLED_CONTACT_TO_YOUR_SUPERVISOR_OR_SUPPORT = 'USER_DISABLED_CONTACT_TO_YOUR_SUPERVISOR_OR_SUPPORT',
  NO_IMAGE = 'NO_IMAGE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  SEARCH_BY_NAME = 'SEARCH_BY_NAME',
  SHARP_AND_DESCRIPTION = 'SHARP_AND_DESCRIPTION',
  NO_RECORDS_FOUND = 'NO_RECORDS_FOUND',
  DO_NOT_USE_ONE_OF_YOUR_OLD_PASSWORDS = 'DO_NOT_USE_ONE_OF_YOUR_OLD_PASSWORDS',
  TRAINING = 'TRAINING',
  CAPITAL_LETTER = 'CAPITAL_LETTER',
  LOWER_CASE = 'LOWER_CASE',
  NUMBER = 'NUMBER',
  SPECIAL_CHARACTER = 'SPECIAL_CHARACTER',
  CONTAIN = 'CONTAIN',
  PASSWORD_MUST = 'PASSWORD_MUST',
  VERIFY_PASSWORD_CRITERIA = 'VERIFY_PASSWORD_CRITERIA',
  REPORT_SECTION = 'REPORT_SECTION',
  EXECUTION = 'EXECUTION',
  RESULTS = 'RESULTS',
  TRUCKS = 'TRUCKS',
  USER_PROGRESS = 'USER_PROGRESS',
  CLOSING_IMAGE = 'CLOSING_IMAGE',
  DPO_ACADEMY = 'DPO_ACADEMY',
  DPO_ACADEMY_MATERIAL = 'DPO_ACADEMY_MATERIAL',
  SEE_MORE = 'SEE_MORE',
  SEE_LESS = 'SEE_LESS',
  CHANGED_ROLE_TO = 'CHANGED_ROLE_TO',
  SECTOR = 'SECTOR',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  SUMMARY = 'SUMMARY',
  ERROR_GETTING_DISTRIBUTION_CENTER_INFO = 'ERROR_GETTING_DISTRIBUTION_CENTER_INFO',
  PLEASE_SELECT_A_BU_TO_SHOW_CHARTS = 'PLEASE_SELECT_A_BU_TO_SHOW_CHARTS',
  ROUTINE_EFFECTIVENESS = 'ROUTINE_EFFECTIVENESS',
  EXTRA_MATERIAL = 'EXTRA_MATERIAL',
  ADHERENCE = 'ADHERENCE',
  WORKSTATIION_EFFECTIVENESS = 'WORKSTATIION_EFFECTIVENESS',
  DOWNLOAD_HC = 'DOWNLOAD_HC',
  UNSAFE_CONDITION = 'UNSAFE_CONDITION',
  SAFE_CONDITION = 'SAFE_CONDITION',
  UNSAFE_ACT = 'UNSAFE_ACT',
  SAFE_ACT = 'SAFE_ACT',
  TO_SEE_THE_DETAILS_OF_AN_ACI_CLICK_ON_A_CARD = 'TO_SEE_THE_DETAILS_OF_AN_ACI_CLICK_ON_A_CARD',
  NO_SIF = 'NO_SIF',
  TYPE_OF_REPORT = 'TYPE_OF_REPORT',
  SEARCH_BY_FOLIO = 'SEARCH_BY_FOLIO',
  SELECT_DATE = 'SELECT_DATE',
  NO_PHOTOS = 'NO_PHOTOS',
  GOLDEN_RULE = 'GOLDEN_RULE',
  IT_DOES_BREAK = 'IT_DOES_BREAK',
  IT_DOES_NOT_BREAK = 'IT_DOES_NOT_BREAK',
  DO_YOU_WANT_TO_CLOSE_THE_CONDITION_AT_THIS_TIME = 'DO_YOU_WANT_TO_CLOSE_THE_CONDITION_AT_THIS_TIME',
  WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_CONDITION = 'WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_CONDITION',
  WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_ACT = 'WRITE_HERE_THE_ACTION_TAKEN_TO_CLOSE_THE_ACT',
  WRITE_HERE_THE_DESCRIPTION_OF_THE_CONSEQUENCE_APPLIED = 'WRITE_HERE_THE_DESCRIPTION_OF_THE_CONSEQUENCE_APPLIED',
  CLOSE_LATER = 'CLOSE_LATER',
  CLOSE_RIGHT_NOW = 'CLOSE_RIGHT_NOW',
  CONDITION_REGISTERED_SUCCESSFULLY = 'CONDITION_REGISTERED_SUCCESSFULLY',
  ACT_REGISTERED_SUCCESSFULLY = 'ACT_REGISTERED_SUCCESSFULLY',
  CONDITION_CLOSED_SUCCESSFULLY = 'CONDITION_CLOSED_SUCCESSFULLY',
  THE_CONDITION_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT = 'THE_CONDITION_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT',
  THE_ACT_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT = 'THE_ACT_WAS_RECORDED_AND_WILL_BE_SENT_TO_THE_APPROPRIATE_DEPARTMENT',
  THE_CONDITION_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED = 'THE_CONDITION_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED',
  START_ANOTHER_REPORT = 'START_ANOTHER_REPORT',
  GO_TO_SAFETY = 'GO_TO_SAFETY',
  RETURN_SIO = 'RETURN_SIO',
  CLOSE_SIO = 'CLOSE_SIO',
  UNASSIGNED_SIOS = 'UNASSIGNED_SIOS',
  WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_SIO = 'WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_SIO',
  WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_ACT = 'WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_ACT',
  WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_CONDITION = 'WRITE_HERE_THE_REASON_FOR_RETURNING_THIS_CONDITION',
  RETURN_SIO_QUESTION_TEXT = 'RETURN_SIO_QUESTION_TEXT',
  RETURN_ACT = 'RETURN_ACT',
  RETURN_CONDITION = 'RETURN_CONDITION',
  IF_THE_SIO_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN = 'IF_THE_SIO_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN',
  IF_THE_CONDITION_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN = 'IF_THE_CONDITION_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN',
  IF_THE_ACT_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN = 'IF_THE_ACT_DOES_NOT_BELONG_TO_YOU_PRESS_ON_RETURN',
  YES_RETURN = 'YES_RETURN',
  RETURNED_SIO = 'RETURNED_SIO',
  THE_SIO_WAS_RETURNED_TO_BE_REASSIGNED = 'THE_SIO_WAS_RETURNED_TO_BE_REASSIGNED',
  ACT_CLOSED_SUCCESSFULLY = 'ACT_CLOSED_SUCCESSFULLY',
  CLOSE_MESSAGE = 'CLOSE_MESSAGE',
  THE_ACT_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED = 'THE_ACT_WAS_SUCCESSFULLY_RECORDED_AND_CLOSED',
  THE_CONDITION_WAS_SUCCESSFULLY_CLOSED = 'THE_CONDITION_WAS_SUCCESSFULLY_CLOSED',
  THE_ACT_WAS_SUCCESSFULLY_CLOSED = 'THE_ACT_WAS_SUCCESSFULLY_CLOSED',
  DO_YOU_WANT_TO_CLOSE_THE_ACT_AT_THIS_TIME = 'DO_YOU_WANT_TO_CLOSE_THE_ACT_AT_THIS_TIME',
  EDIT_SIO = 'EDIT_SIO',
  POSTPONE_SIO = 'POSTPONE_SIO',
  POSTPONE_CONDITION = 'POSTPONE_CONDITION',
  POSTPONE_ACT = 'POSTPONE_ACT',
  POSTPONE = 'POSTPONE',
  CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_CONDITION = 'CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_CONDITION',
  CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_ACT = 'CHOOSE_A_DATE_FOR_WHEN_YOU_WILL_CLOSE_THIS_ACT',
  CONDITION_POSTPONED_SUCCESSFULLY = 'CONDITION_POSTPONED_SUCCESSFULLY',
  ACT_POSTPONED_SUCCESSFULLY = 'ACT_POSTPONED_SUCCESSFULLY',
  THE_CONDITION_WAS_SUCCESSFULLY_POSTPONED = 'THE_CONDITION_WAS_SUCCESSFULLY_POSTPONED',
  THE_ACT_WAS_SUCCESSFULLY_POSTPONED = 'THE_ACT_WAS_SUCCESSFULLY_POSTPONED',
  OK = 'OK',
  UPLOAD_EVIDENCE_OF_ACI_CREATION = 'UPLOAD_EVIDENCE_OF_ACI_CREATION',
  UPLOAD_EVIDENCE_TO_CLOSE_ACI = 'UPLOAD_EVIDENCE_TO_CLOSE_ACI',
  SIF = 'SIF',
  RETURNED_BY = 'RETURNED_BY',
  RETURN_CAUSE = 'RETURN_CAUSE',
  OPENING_OF_QUESTIONS = 'OPENING_OF_QUESTIONS',
  FORBIDDEN_MISSING_PERMISSIONS = 'FORBIDDEN_MISSING_PERMISSIONS',
  START_WORKING_DAY = 'START_WORKING_DAY',
  DETAILS = 'DETAILS',
  POWER_BI_SIOS_T2 = 'POWER_BI_SIOS_T2',
  START_MONTH = 'START_MONTH',
  END_MONTH = 'END_MONTH',
  REPORTED_BY = 'REPORTED_BY',
  ASSIGNED_TO = 'ASSIGNED_TO',
  THE_GOLDEN_RULE_IDENTIFIES_RISKS_WHEN_BREAKING_THE_RULES_PROVIDING_AN_ADDITIONAL_LAYER_OF_SECURITY_IF_YOU_FAIL_TO_COMPLY_THE_USER_WILL_BE_ASKED_FOR_EVIDENCE_WHEN_CLOSING_THE_ACI = 'THE_GOLDEN_RULE_IDENTIFIES_RISKS_WHEN_BREAKING_THE_RULES_PROVIDING_AN_ADDITIONAL_LAYER_OF_SECURITY_IF_YOU_FAIL_TO_COMPLY_THE_USER_WILL_BE_ASKED_FOR_EVIDENCE_WHEN_CLOSING_THE_ACI',
  SIF_BEING_SERIOUS_INJURIES_AND_FATALITIES_WHICH_TRANSLATES_TO_SERIOUS_INJURIES_AND_FATALITIES = 'SIF_BEING_SERIOUS_INJURIES_AND_FATALITIES_WHICH_TRANSLATES_TO_SERIOUS_INJURIES_AND_FATALITIES',
  IS_IT_AN_ACI_TYPE_SIF = 'IS_IT_AN_ACI_TYPE_SIF',
  YOU_HAVE_REACH_THE_LIMIT_OF_REPORTS_PLEASE_TRY_AGAIN_AT = 'YOU_HAVE_REACH_THE_LIMIT_OF_REPORTS_PLEASE_TRY_AGAIN_AT',
  FOLLOW_THE_GOLDEN_RULE = 'FOLLOW_THE_GOLDEN_RULE',
  BREAKS_THE_GOLDEN_RULE = 'BREAKS_THE_GOLDEN_RULE',
  ACI_CONSIDERED = 'ACI_CONSIDERED',
  SERIOUS_INJURIES_AND_FATALITIES = 'SERIOUS_INJURIES_AND_FATALITIES',
  LIST_OF_THE_USERS_THAT_ARE_ASSIGNED_TO_THE_SELECTED_LOCATION = 'LIST_OF_THE_USERS_THAT_ARE_ASSIGNED_TO_THE_SELECTED_LOCATION',
  LIST_OF_THE_USERS_THAT_HAVE_ACCESS_TO_THE_SELECTED_LOCATION_USERS_MAY_APPEAR_MORE_THAN_ONCE_IN_DIFFERENT_LOCATIONS = 'LIST_OF_THE_USERS_THAT_HAVE_ACCESS_TO_THE_SELECTED_LOCATION_USERS_MAY_APPEAR_MORE_THAN_ONCE_IN_DIFFERENT_LOCATIONS',
  YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER = 'YOU_DONT_HAVE_ACCESS_TO_LOCATION_PLEASE_CONTACT_SUPERUSER',
  DOWNLOAD_SKAP_HC_TITLE = 'DOWNLOAD_SKAP_HC_TITLE',
  DOWNLOAD_SKAP_HC_DESCRIPTION = 'DOWNLOAD_SKAP_HC_DESCRIPTION',
  SHOW_DELETED_USERS = 'SHOW_DELETED_USERS',
  FIND_USERS_DESCRIPTION = 'FIND_USERS_DESCRIPTION',
  SEARCH_FOR_DELETED_USERS_BY_SHARP_EMAIL_QR_OR_PIN = 'SEARCH_FOR_DELETED_USERS_BY_SHARP_EMAIL_QR_OR_PIN',
  REHABILITATE_USER = 'REHABILITATE_USER',
  MISSING_DATA_TO_FILL = 'MISSING_DATA_TO_FILL',
  WORKS_AT_FIELD_NEEDS_TO_BE_ADDED_TO_USER = 'WORKS_AT_FIELD_NEEDS_TO_BE_ADDED_TO_USER',
  USER_NEEDS_TO_BE_ASSIGNED_A_POSITION = 'USER_NEEDS_TO_BE_ASSIGNED_A_POSITION',
  USERS_HIRING_DATE_NEEDS_TO_BE_ADDED = 'USERS_HIRING_DATE_NEEDS_TO_BE_ADDED',
  THIS_EMAIL_IS_ALREADY_REGISTER = 'THIS_EMAIL_IS_ALREADY_REGISTER',
  GRADES_CURRENT_STATUS = 'GRADES_CURRENT_STATUS',
  EDIT_OPERATIONS = 'EDIT_OPERATIONS',
  ERROR_LOADING_INFORMATION = 'ERROR_LOADING_INFORMATION',
  EDIT_OPERATION_LOCATION_NOT_SUPPORTED = 'EDIT_OPERATION_LOCATION_NOT_SUPPORTED',
  SUPER_USER_DOESNT_HAVE_ACCESS_TO_LOCATION = 'SUPER_USER_DOESNT_HAVE_ACCESS_TO_LOCATION',
  STRUCTURE_TREE = 'STRUCTURE_TREE',
  RELOAD_DATA = 'RELOAD_DATA',
  DEVIATIONS = 'DEVIATIONS',
  USABILITY_DPO = 'USABILITY_DPO',
  USABILITY_DPO_HEADING = 'USABILITY_DPO_HEADING',
  RULES = 'RULES',
  ACTION_PLANS = 'ACTION_PLANS',
  ZOOM_ROUTINES = 'ZOOM_ROUTINES',
  FIVE_S = '5s',
  SCORECARD = 'SCORECARD',
  TEAM_ROOMS = 'TEAM_ROOMS',
  WORKSTATION = 'WORKSTATION',
  ROAD_SAFETY = 'ROAD_SAFETY',
  ROAD_SAFETY_PLANNING = 'ROAD_SAFETY_PLANNING',
  CRITICAL_PATHS = 'CRITICAL_PATHS',
  DRIVERS = 'DRIVERS',
  RESOURCES = 'RESOURCES',
  TRUCK_ARCHIVE = 'TRUCK_ARCHIVE',
  UPLOAD_NEW_TRUCK_FILE = 'UPLOAD_NEW_TRUCK_FILE',
  DELIVERER_TRUCK_REQUIREMENTS_PDF = 'DELIVERER_TRUCK_REQUIREMENTS_PDF',
  RISK_PATH_FLOW_PDF = 'RISK_PATH_FLOW_PDF',
  INFORMATION_RESOURCES = 'INFORMATION_RESOURCES',
  LOADING_ERROR_PLEASE_RETRY = 'LOADING_ERROR_PLEASE_RETRY',
  UPLOAD_ANOTHER_FILE = 'UPLOAD_ANOTHER_FILE',
  UPLOAD_NEW_DRIVER_FILE = 'UPLOAD_NEW_DRIVER_FILE',
  NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_DRIVER_FILE = 'NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_DRIVER_FILE',
  SELECT_A_DISTRIBUTION_CENTER = 'SELECT_A_DISTRIBUTION_CENTER',
  IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR = 'IN_ORDER_TO_ACCESS_THE_ROAD_SAFETY_SETTINGS_IT_IS_NECESSARY_TO_SELECT_A_LOCATION_USING_THE_TOP_BAR',
  SELECT_A_BUSINESS_UNIT = 'SELECT_A_BUSINESS_UNIT',
  IN_ORDER_TO_ACCESS_DAILY_AGENDA_IT_IS_NECESSARY_TO_SELECT_A_BUSINESS_UNIT_USING_THE_TOP_BAR = 'IN_ORDER_TO_ACCESS_DAILY_AGENDA_IT_IS_NECESSARY_TO_SELECT_A_BUSINESS_UNIT_USING_THE_TOP_BAR',
  UPLOADING_FILE = 'UPLOADING_FILE',
  PROBLEM_WITH_USERS = 'PROBLEM_WITH_USERS',
  THE_FOLLOWING_USERS_HAD_A_PROBLEM_LOADING_IT_MAY_BE_THAT_THEY_DO_NOT_EXIST_IN_THE_SYSTEM_BASE_OR_THAT_THEIR_QR_IS_INCORRECT = 'THE_FOLLOWING_USERS_HAD_A_PROBLEM_LOADING_IT_MAY_BE_THAT_THEY_DO_NOT_EXIST_IN_THE_SYSTEM_BASE_OR_THAT_THEIR_QR_IS_INCORRECT',
  SOME_OF_THE_USERS_DO_NOT_EXIST_IN_THE_SAFETY_DATABASE_REQUEST_THEIR_REGISTRATION_BEFORE_UPLOADING_THEM_HERE = 'SOME_OF_THE_USERS_DO_NOT_EXIST_IN_THE_SAFETY_DATABASE_REQUEST_THEIR_REGISTRATION_BEFORE_UPLOADING_THEM_HERE',
  LAST_UPDATED_ON_RS = 'LAST_UPDATED_ON_RS',
  YOU_ARE_ABOUT_TO_CLOSE_AN_INSECURE_ACT_THAT_IS_A_GOLDEN_RULE = 'YOU_ARE_ABOUT_TO_CLOSE_AN_INSECURE_ACT_THAT_IS_A_GOLDEN_RULE',
  HAS_THE_CORRESPONDING_CONSEQUENCE_FLOW_BEEN_APPLIED = 'HAS_THE_CORRESPONDING_CONSEQUENCE_FLOW_BEEN_APPLIED',
  UPLOAD_NEW_ROUTE_FILE = 'UPLOAD_NEW_ROUTE_FILE',
  UPLOAD_ROAD_SAFETY_CHECKLIST_STRUCTURE = 'UPLOAD_ROAD_SAFETY_CHECKLIST_STRUCTURE',
  NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_CRITICAL_PATH_FILE = 'NO_DATA_LOADED_YET_PRESS_UPLOAD_NEW_CRITICAL_PATH_FILE',
  RETURN_TO_CRITICAL_PATHS = 'RETURN_TO_CRITICAL_PATHS',
  ROUTE = 'ROUTE',
  ROUTE_DATA = 'ROUTE_DATA',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  ROUTE_ID = 'ROUTE_ID',
  LAST_UPDATE = 'LAST_UPDATE',
  LAST_DRIVER = 'LAST_DRIVER',
  DESTINATION = 'DESTINATION',
  SEE_ROUTE = 'SEE_ROUTE',
  DRIVER_SUCCESSFULLY_REMOVED = 'DRIVER_SUCCESSFULLY_REMOVED',
  WITHOUT_CODE = 'WITHOUT_CODE',
  LAST_TRUCK = 'LAST_TRUCK',
  DRIVER = 'DRIVER',
  TRUCK = 'TRUCK',
  EVALUATOR = 'EVALUATOR',
  FIND_ANOTHER_ROUTE = 'FIND_ANOTHER_ROUTE',
  REMOVE = 'REMOVE',
  FROM_THE_ROUTE = 'FROM_THE_ROUTE',
  DRIVERS_SUITABLE_FOR_THE_SELECTED_ROUTE = 'DRIVERS_SUITABLE_FOR_THE_SELECTED_ROUTE',
  APPLY_CHANGE = 'APPLY_CHANGE',
  TRUCKS_SUITABLE_FOR_SELECTED_ROUTES = 'TRUCKS_SUITABLE_FOR_SELECTED_ROUTES',
  SEARCH_TRUCK = 'SEARCH_TRUCK',
  PLANNING_FOR_TODAY = 'PLANNING_FOR_TODAY',
  SELECT_RS = 'SELECT_RS',
  SELECT_DRIVER = 'SELECT_DRIVER',
  ADD_DRIVER = 'ADD_DRIVER',
  AT_RISK = 'AT_RISK',
  CRITICAL = 'CRITICAL',
  RELIEF_DRIVER = 'RELIEF_DRIVER',
  TIME_FOR_CHANGE = 'TIME_FOR_CHANGE',
  DRIVER_WITHOUR_ROUTE = 'DRIVER_WITHOUR_ROUTE',
  CHANGE_DRIVER = 'CHANGE_DRIVER',
  CHANGE_TRUCK = 'CHANGE_TRUCK',
  THERE_ARE_NO_OPTIONS_AVAILABLE_CANNOT_GO_ON_ROUTE = 'THERE_ARE_NO_OPTIONS_AVAILABLE_CANNOT_GO_ON_ROUTE',
  DUPLICATE_ON_ROTUE = 'DUPLICATE_ON_ROTUE',
  LACK_OF_DRIVER_CANNOT_GO_ON_ROUTE = 'LACK_OF_DRIVER_CANNOT_GO_ON_ROUTE',
  SEARCH_DRIVER = 'SEARCH_DRIVER',
  ROUTE_SUCCESSFULLY_UPDATED = 'ROUTE_SUCCESSFULLY_UPDATED',
  PLEASE_SELECT_RELY_TIME_FOR_THIS_TRIP = 'PLEASE_SELECT_RELY_TIME_FOR_THIS_TRIP',
  DRIVER_MATRIX = 'DRIVER_MATRIX',
  CREATE_USER = 'CREATE_USER',
  LOCATION_SELECT = 'LOCATION_SELECT',
  CREATE_BY_PIN = 'CREATE_BY_PIN',
  CREATE_BY_SHARP = 'CREATE_BY_SHARP',
  USER_INFORMATION = 'USER_INFORMATION',
  FULL_NAME = 'FULL_NAME',
  ENTER_USERS_FULL_NAME = 'ENTER_USERS_FULL_NAME',
  SAP = 'SAP',
  ENTER_USERS_SAP = 'ENTER_USERS_SAP',
  SELECT_USERS_POSITION = 'SELECT_USERS_POSITION',
  SELECT_HIRING_DATE = 'SELECT_HIRING_DATE',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
  SAP_MUST_CONTAIN_8_DIGITS = 'SAP_MUST_CONTAIN_8_DIGITS',
  SAP_SHARP_AVAILABLE = 'SAP_SHARP_AVAILABLE',
  SAP_SHARP_ALREADY_REGISTERED = 'SAP_SHARP_ALREADY_REGISTERED',
  USER_CREATED_SUCCESSFULLY = 'USER_CREATED_SUCCESSFULLY',
  SAP_SHOULD_ONLY_CONTAIN_NUMBERS = 'SAP_SHOULD_ONLY_CONTAIN_NUMBERS',
  PLEASE_ENTER_8_CHARACTERS = 'PLEASE_ENTER_8_CHARACTERS',
  SPECIAL_CHARACTERS_NOT_ALLOWED = 'SPECIAL_CHARACTERS_NOT_ALLOWED',
  PATIO_DRIVER = 'PATIO_DRIVER',
  EDIT_USER_WITHOUT_ROLE = 'EDIT_USER_WITHOUT_ROLE',
  ROLE_AND_LOCATION_TYPE_MISMATCH = 'ROLE_AND_LOCATION_TYPE_MISMATCH',
  AUTOMATIC_ASSIGNMENT_OF_ACIS = 'AUTOMATIC_ASSIGNMENT_OF_ACIS',
  ALLOW_AUTOMATIC_ASSIGNMENT_OF_ACIS = 'ALLOW_AUTOMATIC_ASSIGNMENT_OF_ACIS',
  PLEASE_SELECT_AREA = 'PLEASE_SELECT_AREA',
  PLEASE_SELECT_SUBAREA = 'PLEASE_SELECT_SUBAREA',
  SELECT_LOCATIONS_FOR_AUTO_ASSIGN_SIOS_IN_PERMISSION_TAB = 'SELECT_LOCATIONS_FOR_AUTO_ASSIGN_SIOS_IN_PERMISSION_TAB',
  SEARCH_FOR_OWD_HERE = 'SEARCH_FOR_OWD_HERE',
  CHECKLIST_DELETED_SUCCESSFULLY = 'CHECKLIST_DELETED_SUCCESSFULLY',
  CHECKLIST_EDITED_CORRECTLY = 'CHECKLIST_EDITED_CORRECTLY',
  ERROR_PLEASE_RETRY = 'ERROR_PLEASE_RETRY',
  NO_DATA_FOUND_FOR_THIS_SECTION_SO_FAR = 'NO_DATA_FOUND_FOR_THIS_SECTION_SO_FAR',
  ENTER_YOUR_NAME = 'ENTER_YOUR_NAME',
  ENTER_YOUR_EMAIL = 'ENTER_YOUR_EMAIL',
  ENTER_YOUR_FIRST_SURNAME = 'ENTER_YOUR_FIRST_SURNAME',
  ENTER_YOUR_SECOND_SURNAME = 'ENTER_YOUR_SECOND_SURNAME',
  DATE_MUST_BE_WITHIN_THE_PAST_100_YEARS = 'DATE_MUST_BE_WITHIN_THE_PAST_100_YEARS',
  DAILY_AGENDA = 'DAILY_AGENDA',
  SCHEDULE = 'SCHEDULE',
  ACTIVITIES = 'ACTIVITIES',
  TOTAL_MONTH = 'TOTAL_MONTH',
  EDIT_SCHEDULE = 'EDIT_SCHEDULE',
  DRAG_A_CREATED_TASK_TO_START = 'DRAG_A_CREATED_TASK_TO_START',
  DELETE_POSITION = 'DELETE_POSITION',
  THERE_ARE_NO_POSITIONS_CREATED_YET_TO_ADD_A_NEW_ONE_PRESS_ADD_POSITION = 'THERE_ARE_NO_POSITIONS_CREATED_YET_TO_ADD_A_NEW_ONE_PRESS_ADD_POSITION',
  THRE_ARE_NO_TASKS_CREATED_YET_TO_CREATE_A_NEW_ONE_PRESS_NEW = 'THRE_ARE_NO_TASKS_CREATED_YET_TO_CREATE_A_NEW_ONE_PRESS_NEW',
  THE_TASKS_OF_THE_DPO_MODULES_ARE_ALREADY_CONNECTD_TO_THE_DAILY_AGENDA_THEY_DO_NOT_NEED_TO_BE_CREATED = 'THE_TASKS_OF_THE_DPO_MODULES_ARE_ALREADY_CONNECTD_TO_THE_DAILY_AGENDA_THEY_DO_NOT_NEED_TO_BE_CREATED',
  NEW = 'NEW',
  ADD_POSITION = 'ADD_POSITION',
  POSITIONS_OF = 'POSITIONS_OF',
  ACCUMULATIVE = 'ACCUMULATIVE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  NEW_ACTIVITY = 'NEW_ACTIVITY',
  WRITE_ACTIVITY_NAME = 'WRITE_ACTIVITY_NAME',
  WRITE_ENGLISH_ACTIVITY_NAME = 'WRITE_ENGLISH_ACTIVITY_NAME',
  WRITE_DESCRIPTION = 'WRITE_DESCRIPTION',
  THIS_ACTIVITY_WILL_ONLY_DISPLAY_ONE_QUESTION_IN_THE_MOBILE_APP_WHICH_WILL_BE_TO_REQUEST_PHOTOGRAPHIC_EVIDENCE = 'THIS_ACTIVITY_WILL_ONLY_DISPLAY_ONE_QUESTION_IN_THE_MOBILE_APP_WHICH_WILL_BE_TO_REQUEST_PHOTOGRAPHIC_EVIDENCE',
  SELECT_PILLAR = 'SELECT_PILLAR',
  CREATE = 'CREATE',
  PROGRAMMING_OF = 'PROGRAMMING_OF',
  TO_EDIT_ANOTHER_ONE_GO_BACK_AND_SELECT_A_DIFFERENT_BU = 'TO_EDIT_ANOTHER_ONE_GO_BACK_AND_SELECT_A_DIFFERENT_BU',
  EDITING_AGENDA_PROGRAMMING = 'EDITING_AGENDA_PROGRAMMING',
  SAVE_CHANGES = 'SAVE_CHANGES',
  PROGRAMMING_AGENDA = 'PROGRAMMING_AGENDA',
  WATCHING_PROGRAMMING_OF = 'WATCHING_PROGRAMMING_OF',
  THERE_IS_NO_SCHEDULE_YET_TO_START_PRESS_EDIT_SCHEDULE = 'THERE_IS_NO_SCHEDULE_YET_TO_START_PRESS_EDIT_SCHEDULE',
  THERE_ARE_N0_SCHEDULES_RECORDED_FOR_THE_SELECTED_BU_YET_START_BY_PRESSING_THE_EDIT_SCHEDULE_BUTTON_AT_THE_TOP_RIGHT_TO_BEGIN_ADDING_TASKS_TO_THE_DIFFERENT_POSITIONS = 'THERE_ARE_N0_SCHEDULES_RECORDED_FOR_THE_SELECTED_BU_YET_START_BY_PRESSING_THE_EDIT_SCHEDULE_BUTTON_AT_THE_TOP_RIGHT_TO_BEGIN_ADDING_TASKS_TO_THE_DIFFERENT_POSITIONS',
  PROGRAMMING_EFFECTIVE_FROM = 'PROGRAMMING_EFFECTIVE_FROM',
  THESE_CHANGES_WILL_TAKE_EFFECT_UNTIL = 'THESE_CHANGES_WILL_TAKE_EFFECT_UNTIL',
  SELECT_THE_BU_AND_PERIOD_TO_DOWNLOAD_THE_DATA = 'SELECT_THE_BU_AND_PERIOD_TO_DOWNLOAD_THE_DATA',
  DOWNLOADING_PROGRAMMING_OF = 'DOWNLOADING_PROGRAMMING_OF',
  THE_QUARTERLY_ACTIVITIES_DO_NOT_COUNT_FOR_THE_MONTHLY_SUMS_OF_THE_USERS = 'THE_QUARTERLY_ACTIVITIES_DO_NOT_COUNT_FOR_THE_MONTHLY_SUMS_OF_THE_USERS',
  THESE_ACTIVITIES_HAVE_INDIVIDUAL_DUE_DATES = 'THESE_ACTIVITIES_HAVE_INDIVIDUAL_DUE_DATES',
  YOU_HAVE_UNTIL = 'YOU_HAVE_UNTIL',
  QUARTERLY_PROGRESS = 'QUARTERLY_PROGRESS',
  MONTHLY_PROGRESS = 'MONTHLY_PROGRESS',
}

export enum SKAP_TAB_TYPES {
  WAREHOUSE_HELPERS = 'WAREHOUSE_HELPERS',
  FORKLIFTERS = 'FORKLIFTERS',
  DISTRIBUTION = 'DISTRIBUTION',
}
export enum FILTER_ACCESS_LEVELS {
  DISTRIBUTION_CENTER = 'distributionCenter',
  SUBZONE = 'subzone',
  ZONE = 'zone',
  BUSINESS_UNIT = 'bu',
  CLUSTER = 'cluster',
}

export enum POSTS_TYPES {
  NEWS = 'NEWS',
  BEST_PRACTICES = 'BEST_PRACTICES',
  PREVENTION_CAMPAIGNS = 'PREVENTION_CAMPAIGNS',
}

export enum ATTACHMENT_TYPES {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  PDF = 'PDF',
}

export enum PLACE_OF_QUARANTINE {
  HOME = 'HOME',
  HOSPITAL = 'HOSPITAL',
}

export enum GLOBAL_SEARCH_TYPES {
  LOCATION = 'LOCATION',
}

export enum ACTION_LOG_STATUS {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  FOR_REVISION = 'FOR_REVISION',
  COMPLETED = 'COMPLETED',
}

export enum ACTION_LOG_MEETINGS {
  DIARIA_LOGÍSTICA = 'DIARIA_LOGÍSTICA',
  RUTINA_MATUTINA_DE_REPARTO = 'RUTINA_MATUTINA_DE_REPARTO',
  RUTINA_DE_CAMBIO_DE_TURNO = 'RUTINA_DE_CAMBIO_DE_TURNO',
  RUTINA_SEMANAL_DE_ALMACÉN = 'RUTINA_SEMANAL_DE_ALMACÉN',
  RUTINA_SEMANAL_DE_DIST = 'RUTINA_SEMANAL_DE_DIST',
  RUTINA_SEMANAL_DE_PEOPLE = 'RUTINA_SEMANAL_DE_PEOPLE',
  RUTINA_SEMANAL_DE_FLOTA = 'RUTINA_SEMANAL_DE_FLOTA',
  RUTINA_SEMANAL_DE_PLANNING = 'RUTINA_SEMANAL_DE_PLANNING',
  RUTINA_SEMANAL_DE_SAFETY = 'SEMANAL_DE_SAFETY_RUTINA_SEMANAL_DE_SAFETY',
  RUTINE_SEMANAM_DE_GESTION = 'RUTINE_SEMANAM_DE_GESTION',
}

export enum ACTION_LOG_SUMMARY_MODAL_STATUS {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  SUMMARY = 'SUMMARY',
}
export enum ROUTINE_COMPLIANCE {
  HEADING = 'Routine compliance',
  COMPLETED_PERCENTAGE = 'Routine %',
  EXECUTED = 'Executed',
  PENDING = 'Pending',
  SEARCH = 'Search By GRO',
  REGION = 'Country/ Region',
  PLANNED = 'Planned',
  REAL = 'Real',
  COMPLIANCE = 'Compliance',
  DOWNLOAD_DATA = 'Download Data',
  BU = 'Per BU',
  RANKING = 'DC Ranking for MAZ',
  CD = 'DC',
}
export enum BU {
  Colombia = 'CO',
  Mexico = 'MEX',
  Ecuador = 'EC',
  Perú = 'PE',
  CAC = 'CAC',
}
export enum COMMENT_CREATE_TYPE {
  ACTION_LOG = 'Action Log',
  CAPEX = 'Capex',
}
export enum YES_NO_OPTIONS {
  YES = 'YES',
  NO = 'NO',
}

export enum SIF_TYPES {
  PRECURSOR = 'PRECURSOR',
  POTENTIAL = 'POTENTIAL',
}

export enum ROLES_CATEGORY {
  SUPPLY = 'SUPPLY',
  LOGISTICS = 'LOGISTICS',
  MIXED = 'MIXED',
}

export enum ROAD_SAFETY_ROUTE_STATUS {
  CRITICAL = 'CRITICAL',
  AT_RISK = 'AT_RISK',
  OK = 'OK',
}

export enum DA_TASK_FREQUENCY {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  QUERTERLY = 'MONTHLY',
}

export enum LOCATION_LEVEL {
  DISTRIBUTION_CENTER = 'distributionCenter',
  SUBZONE = 'subzone',
  ZONE = 'zone',
  BUSINESS_UNIT = 'bu',
  CLUSTER = 'cluster',
}
