/* eslint-disable @typescript-eslint/dot-notation */
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_STATUS, TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import BasicButton from 'components/Button/BasicButton';
import checklistSearch from '../../../assets/icons/input-search.svg';
import activityNameIcon from '../../../assets/icons/checklist.svg';
import activityDescriptionIcon from '../../../assets/icons/description.svg';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import LabelledInputWithImage from 'components/Input/LabelledInputWithImage/LabelledInputWithImage';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import './EditDailyAgenda.scss';

import axios from 'axios';
import { selectSelectedBusinessUnit } from 'store/selectors/globalFilters.selectors';
import { useSelector } from 'react-redux';
import { useGetAllPillarLazyQuery } from 'generated/graphql';
import { selectUser } from 'store/selectors/user.selectors';
import CircularProgress from '@mui/material/CircularProgress';

type ActivityType = {
  id: string;
  label: string;
  labelEn: string;
  description: string;
  pillar: any;
  slug: string;
};

enum DndCards {
  CARD_1 = 'CARD_1',
  CARD_2 = 'CARD_2',
}

enum Frequency {
  DAILY = 'DAILY',
  DAILY_ACCUMULATIVE = 'DAILY_ACCUMULATIVE',
  WEEKLY_ACCUMULATIVE = 'WEEKLY_ACCUMULATIVE',
  BIWEEKLY_ACCUMULATIVE = 'BIWEEKLY_ACCUMULATIVE',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

const EditDailyAgenda = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedBusinessUnit = useSelector(selectSelectedBusinessUnit);
  const userDetails = useSelector(selectUser);

  const [getPillars, { data: getPillarsRes }] = useGetAllPillarLazyQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pillarsData = getPillarsRes?.getAllPillar;

  const frequencyList = [
    { label: t(TRANSLATIONS.DAILY), slug: Frequency.DAILY },
    { label: `${t(TRANSLATIONS.DAILY)} ${t(TRANSLATIONS.ACCUMULATIVE)}  `, slug: Frequency.DAILY_ACCUMULATIVE },
    { label: t(TRANSLATIONS.WEEKLY), slug: Frequency.WEEKLY },
    { label: `${t(TRANSLATIONS.WEEKLY)} ${t(TRANSLATIONS.ACCUMULATIVE)}  `, slug: Frequency.WEEKLY_ACCUMULATIVE },
    { label: t(TRANSLATIONS.BIWEEKLY), slug: Frequency.BIWEEKLY },
    { label: `${t(TRANSLATIONS.BIWEEKLY)} ${t(TRANSLATIONS.ACCUMULATIVE)}  `, slug: Frequency.BIWEEKLY_ACCUMULATIVE },
    { label: t(TRANSLATIONS.MONTHLY), slug: Frequency.MONTHLY },
    { label: t(TRANSLATIONS.QUARTERLY), slug: Frequency.QUARTERLY },
  ];

  const inputRef = useRef<HTMLInputElement>(null);

  const [dndChecklistList, setDndChecklistList] = useState<any>({
    [DndCards.CARD_1]: [],
    [DndCards.CARD_2]: [],
  });
  const [search, setSearch] = useState<string>('');
  const [openDropdownIndex, setOpenDropdownIndex] = useState<string | null>(null);
  const [openPositionsDropdown, setOpenPositionsDropdown] = useState<boolean>(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState<boolean>(false);
  const [isEditingActivity, setIsEditingActivity] = useState<boolean>(false);
  const [newActivities, setNewActivities] = useState<any>([]);
  const [newActivityObject, setNewActivityObject] = useState<ActivityType>({ id: '', label: '', labelEn: '', description: '', pillar: { name: '', id: null }, slug: '' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [positions, setPositions] = useState<any>([]);
  const [originalPositions, setOriginalPositions] = useState<any>([]);
  const [fetchStatus, setFetchStatus] = useState<FETCH_STATUS>(FETCH_STATUS.NO_ACTIVE_REQUEST);


  const searchChecklists = useCallback(() => {
    const tempActivities = newActivities?.filter((activity: any) => activity
      ?.label?.toLowerCase().includes(search?.toLocaleLowerCase()));
    if (tempActivities.length > 0) {
      const list = JSON.parse(JSON.stringify(dndChecklistList));
      list[DndCards.CARD_1] = tempActivities;
      setDndChecklistList(list);
    } else {
      const list = {
        [DndCards.CARD_1]: [],
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (search) {
      searchChecklists();
    } else {
      const list = {
        [DndCards.CARD_1]: newActivities,
        [DndCards.CARD_2]: [...dndChecklistList[DndCards.CARD_2]],
      };
      setDndChecklistList(list);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, searchChecklists]);


  useEffect(() => {
    setFetchStatus(FETCH_STATUS.NO_ACTIVE_REQUEST);
    const fetchData = async () => {
      try {
        setFetchStatus(FETCH_STATUS.PENDING);
        const [tasksRes, positionScheduleRes, positionsRes] = await Promise.all([
          axios.get(`/api/v1/da-get-task-per-bu?buId=${selectedBusinessUnit.id}`),
          axios.get(`/api/v1/da-position-schedule?buId=${selectedBusinessUnit.id}`),
          axios.get(`/api/v1/da-get-operator-positions-by-bu?buId=${selectedBusinessUnit.id}`),
        ]);

        const positionsMap = new Map(
          positionsRes.data.map((position: any) => [position.position, position]),
        );

        setOriginalPositions(positionsRes.data);
        const editableContentPrefill = positionScheduleRes?.data?.inactiveSchedule
          ? positionScheduleRes.data.inactiveSchedule.content.positionTasks
          : positionScheduleRes.data.activeSchedule
            ? positionScheduleRes.data.activeSchedule.content.positionTasks
            : [];

        const card2Data = editableContentPrefill.flatMap((positionTask, positionIndex) =>
          positionTask.tasks.map((task, taskIndex) => {
            return ({
              id: `task-${positionIndex}-${taskIndex}-${task.taskSlug}`,
              position: positionTask.positionName,
              label: task.taskName,
              slug: task.taskSlug,
              frequency: task.taskFrequency,
              type: task.module,
              pillar: task.pillar || task.pillar.name,
              accumulative: task.accumulative,
            });
          }),
        );

        const positionsInCard2Data = new Set(card2Data.map(task => task.position));

        const filteredPositions = positionsRes.data.filter(
          (position: any) => !positionsInCard2Data.has(position.position),
        );

        if (filteredPositions.length > 0) {
          setPositions(filteredPositions);
        } else {
          setPositions(positionsRes.data);
        }

        const allPositions = editableContentPrefill
          .map((positionTask: any) => (positionsMap.get(positionTask.positionName) as any)?.position)
          .filter(Boolean);

        setSelectedPositions(allPositions);

        const combinedArray = [
          ...tasksRes.data[0].checklistsAvailable.flat(),
          ...tasksRes.data[0].routinesAvailable.flat(),
          ...tasksRes.data[0].quickRoutines.flat(),
        ];
        const mappedTasks = combinedArray.map((task, index) => ({
          id: task.id ? `${task.id.toString()} - ${task.slug}` : `${index.toString()} - ${task.slug}`,
          label: task.name,
          pillar: task.checklistPillar?.name || task.routinePillar?.name || task.pillar?.name,
          type: task.ChecklistType || (task.routinePillar ? 'ROUTINES' : 'QUICK_ROUTINES'),
          slug: task.slug,
        }));

        setDndChecklistList(prevState => ({
          ...prevState,
          [DndCards.CARD_1]: mappedTasks,
          [DndCards.CARD_2]: card2Data || [],
        }));
        setTimeout(() => {
          setFetchStatus(FETCH_STATUS.SUCCESS);
        }, 2000);
      } catch (error) {
        setFetchStatus(FETCH_STATUS.FAIL);
        console.error('Error:', error);
      }
    };

    fetchData();
    getPillars({ variables: { pillarsToIgnore: [] } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessUnit]);

  function frequencySlug(frequency: Frequency): string {
    switch (frequency) {
      case Frequency.DAILY_ACCUMULATIVE:
        return Frequency.DAILY;
      case Frequency.WEEKLY_ACCUMULATIVE:
        return Frequency.WEEKLY;
      case Frequency.BIWEEKLY_ACCUMULATIVE:
        return Frequency.BIWEEKLY;
      case Frequency.DAILY:
      case Frequency.WEEKLY:
      case Frequency.BIWEEKLY:
      case Frequency.MONTHLY:
      case Frequency.QUARTERLY:
        return frequency;
      default:
        return 'unknown';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const memoizedPositions = useMemo(() => positions, [positions]);

  const onPressSaveChanges = async () => {
    setFetchStatus(FETCH_STATUS.PENDING);
    const groupedData = dndChecklistList.CARD_2.reduce((acc, task) => {
      const positionName = task.position;

      let position = acc.find(p => p.positionName === positionName);
      if (!position) {
        position = { positionName, tasks: [] };
        acc.push(position);
      }

      position.tasks.push({
        taskName: task.label,
        taskSlug: task.slug,
        module: task.type,
        taskFrequency: frequencySlug(task.frequency.slug),
        accumulative: task.isAccumulative || false,
        pillar: task.pillar.name || task.pillar,
      });
      return acc;
    }, []);

    const payload = {
      content: {
        positionTasks: groupedData,
      },
      buId: selectedBusinessUnit?.id,
      month: new Date().getMonth() + 1,
      createdByUserId: userDetails?.get('id'),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null,
    };

    const url = '/api/v1/da-position-schedule';

    try {
      await axios.post(url, payload);
      navigate('/viewDailyAgenda', { replace: true });
      setTimeout(() => {
        setFetchStatus(FETCH_STATUS.SUCCESS);
      }, 5000);
    } catch (error) {
      setFetchStatus(FETCH_STATUS.FAIL);
      console.error('%c⧭', error.response || error.message);
    }
  };

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    if (!destination) return;

    const sourceColumn = source?.droppableId?.split('-')[0] as DndCards;
    const destinationColumn = destination?.droppableId?.split('-')[0] as DndCards;
    const destinationPosition = destination?.droppableId?.split('-')[1];

    if (sourceColumn === destinationColumn) {
      const items = Array.from(dndChecklistList?.[sourceColumn]);
      const [removed] = items?.splice(source?.index, 1);
      items.splice(destination?.index, 0, removed);
      if (sourceColumn === DndCards.CARD_1) {
        setNewActivities(items);
      }
      setDndChecklistList((prevState: any) => ({
        ...prevState,
        [sourceColumn]: items,
      }));
    } else {
      if (destinationColumn === DndCards.CARD_2) {
        const movingCard = dndChecklistList?.[sourceColumn]?.find(
          (_: any, index: number) => index === source?.index,
        );

        const newMovingCard = {
          ...movingCard,
          id: `${movingCard.id}-to-${destinationColumn}-${destinationPosition}`,
          position: destinationPosition,
          frequency: frequencyList[0],
        };

        setDndChecklistList((preState: any) => {
          const destinationPositionsItems = dndChecklistList?.[destinationColumn]?.filter((i: any) => i?.position === destinationPosition);
          const itemsWithoutDestinationPosition = Array.from(dndChecklistList?.[destinationColumn])?.filter((i: any) => i?.position !== destinationPosition);

          const updatedItems = [
            ...destinationPositionsItems?.slice?.(0, destination?.index),
            newMovingCard,
            ...destinationPositionsItems?.slice?.(destination?.index),
          ];

          return ({
            ...preState,
            [sourceColumn]: preState[sourceColumn],
            [destinationColumn]: [...updatedItems, ...itemsWithoutDestinationPosition],
          });
        });
      } else {
        const movingCard = dndChecklistList?.[sourceColumn]?.find(
          (_: any, index: number) => index === source?.index,
        );

        let updatedCard1Items = [];
        const updatedItems = Array.from(dndChecklistList?.[destinationColumn]);
        updatedItems.splice(destination?.index, 0, { ...movingCard, id: `${movingCard.id}-to-${destinationColumn}` });

        if (search?.length) {
          updatedCard1Items = [
            ...newActivities?.slice?.(0, destination?.index),
            { ...movingCard },
            ...newActivities?.slice?.(destination?.index),
          ];
        }

        setNewActivities(updatedCard1Items?.length ? updatedCard1Items : updatedItems);
        setDndChecklistList((preState: any) => ({
          ...preState,
          [sourceColumn]: preState[sourceColumn],
          [destinationColumn]: updatedItems,
        }));
      }
    }
  }

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  const handleActivityNameChange = async (event: any) => {
    setNewActivityObject({
      ...newActivityObject,
      label: event.target.value,
    });
  };

  const handleActivityNameEnChange = async (event: any) => {
    setNewActivityObject({
      ...newActivityObject,
      labelEn: event.target.value,
    });
  };

  const handleActivityDescriptionChange = async (event: any) => {
    setNewActivityObject({
      ...newActivityObject,
      description: event.target.value,
    });
  };

  const onAddActivity = () => {
    setShowAddActivityModal(true);
  };

  const onDeletePosition = (positionD: string) => {
    const deletedPosition = originalPositions?.find((item: any) => item.position === positionD);
    const clonedPositions = JSON.parse(JSON.stringify(positions));

    const clonedSelectedPositions = JSON.parse(JSON.stringify(selectedPositions));
    const filteredSelectedPosition = clonedSelectedPositions?.filter((i: any) => i !== positionD);
    clonedPositions?.push(deletedPosition);

    const clonedDndItems = JSON.parse(JSON.stringify(dndChecklistList));
    const filteredDndItems = clonedDndItems?.[DndCards.CARD_2]?.filter((i: any) => i?.position !== positionD);
    setPositions(clonedPositions);

    setSelectedPositions(filteredSelectedPosition);
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_2]: filteredDndItems,
    });
  };

  const handleFrequencyChange = (data: any, selectedItemIndex: number, position: string) => {
    const selectedFrequency = frequencyList.find(item => item.slug === data?.key) || frequencyList[0];
    const selectedCard = dndChecklistList?.[DndCards.CARD_2]?.filter((i: any) => i?.position === position)?.find(
      (_: any, index: number) => index === selectedItemIndex,
    );
    const destinationPositionsItems = dndChecklistList?.[DndCards.CARD_2]?.filter((i: any) => i?.position === position);
    const itemsWithoutDestinationPosition = Array.from(dndChecklistList?.[DndCards.CARD_2])?.filter((i: any) => i?.position !== position);

    const updatedItems = [
      ...destinationPositionsItems?.slice?.(0, selectedItemIndex),
      { ...selectedCard, frequency: selectedFrequency },
      ...destinationPositionsItems?.slice?.(selectedItemIndex + 1),
    ];

    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_2]: [...updatedItems, ...itemsWithoutDestinationPosition],
    });
    setOpenDropdownIndex(null);
  };

  const handleAddPosition = ({ key }: { key: any }) => {
    const filteredPosition = positions?.find((item: any) => item.position === key) || memoizedPositions[0];
    const clonedPositions = JSON.parse(JSON.stringify(selectedPositions));
    clonedPositions?.push(filteredPosition?.position);
    setSelectedPositions(clonedPositions);
    setPositions(positions?.filter((item: any) => item?.position !== key));
    setOpenPositionsDropdown(false);
  };

  const onDeleteActivity = (activityId: string, activityPosition: string) => {
    const clonedDndItems = JSON.parse(JSON.stringify(dndChecklistList));
    const filteredDndItems = clonedDndItems?.[DndCards.CARD_2]?.filter((i: any) => i?.position === activityPosition)?.filter((i: any) => i?.id !== activityId);
    const itemsWithoutActivityPosition = Array.from(dndChecklistList?.[DndCards.CARD_2])?.filter((i: any) => i?.position !== activityPosition);
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_2]: [...filteredDndItems, ...itemsWithoutActivityPosition],
    });
  };

  const onChangePillar = async (e: any) => {
    const selectedPillar = pillarsData?.find((pillar: any) => pillar?.name === e?.label);
    setNewActivityObject({
      ...newActivityObject,
      pillar: selectedPillar,
    });
  };

  const onToggleNewActivityModal = () => {
    setShowAddActivityModal(false);
    setNewActivityObject({
      id: '',
      label: '',
      labelEn: '',
      description: '',
      pillar: {
        name: '',
        id: null,
      },
      slug: '',
    });
  };

  function generateRandomId(prefix = 'item') {
    const randomPart = Math.random().toString(36).substring(2, 8);
    const timestamp = Date.now().toString(36);
    return `${prefix}-${timestamp}-${randomPart}`;
  }

  const onCreateNewActivity = async () => {
    const payload = {
      name: newActivityObject.label,
      nameEn: newActivityObject.labelEn || '',
      description: newActivityObject.description,
      pillarId: newActivityObject.pillar.id,
    };

    const url = '/api/v1/quickRoutines';
    let quickRoutineData: any;
    const createNewActivity = async () => {
      try {
        quickRoutineData = await axios.post(url, payload);
      } catch (error) {
        console.error('%c⧭', error.response || error.message);
      }
    };
    await createNewActivity();
    const newActivity = {
      ...newActivityObject,
      id: generateRandomId(),
      slug: quickRoutineData?.data?.slug,
      type: 'QUICK_ROUTINES',
    };
    const cardItems = JSON.parse(JSON.stringify(dndChecklistList[DndCards.CARD_1]));
    cardItems?.push(newActivity);
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_1]: cardItems,
    });
    setNewActivities(cardItems);
    onToggleNewActivityModal();
  };

  // const onEditActivity = (selectedActivity: ActivityType) => {
  //   const cardItems = JSON.parse(JSON.stringify(dndChecklistList[DndCards.CARD_1]));
  //   const filteredActivity = cardItems?.find((c: any) => c?.id === selectedActivity?.id);
  //   setNewActivityObject(filteredActivity);
  //   setShowAddActivityModal(true);
  //   setIsEditingActivity(true);
  // };

  const onSaveEditedActivity = () => {
    const cardItems = JSON.parse(JSON.stringify(dndChecklistList[DndCards.CARD_1]));
    const updatedItems = cardItems?.map((c: any) => {
      if (c?.id === newActivityObject?.id) {
        return newActivityObject;
      }
      return c;
    });
    setDndChecklistList({
      ...dndChecklistList,
      [DndCards.CARD_1]: updatedItems,
    });
    setIsEditingActivity(false);
    onToggleNewActivityModal();
  };

  const isNewActivityCreateButtonDisabled = useMemo(() => {
    let flag = false;
    if (!newActivityObject?.label.length || !newActivityObject?.description?.length || !newActivityObject?.pillar?.name?.length) {
      flag = true;
    }
    return flag;
  }, [newActivityObject]);

  const onPressBack = () => {
    navigate('/viewDailyAgenda', { replace: true });
  };

  return (
    <div className="editDailyAgenda">
      {showAddActivityModal && (
        <Modal
          modalContainerStyles={{
            width: '50vw',
            left: '26%',
            maxHeight: '90vh',
            minHeight: '20vh',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '40%',
            transform: 'translateY(-50%)',
          }}
        >
          <div className="editDailyAgenda__addActivityModal">
            <p className="editDailyAgenda__addActivityModal__label">{t(TRANSLATIONS.NEW_ACTIVITY)}</p>
            <InputWithLabel
              inputImg={activityNameIcon}
              onChange={handleActivityNameChange}
              placeholder={t(TRANSLATIONS.WRITE_ACTIVITY_NAME)}
              value={newActivityObject.label}
              labelClassName="search-header"
              type="text"
              maxLength={1000}
              inputClassName="search-input"
              containerClassName="editDailyAgenda__addActivityModal__name"
            />
            <InputWithLabel
              inputImg={activityNameIcon}
              onChange={handleActivityNameEnChange}
              placeholder={t(TRANSLATIONS.WRITE_ENGLISH_ACTIVITY_NAME)}
              value={newActivityObject.labelEn}
              labelClassName="search-header"
              type="text"
              maxLength={1000}
              inputClassName="search-input"
              containerClassName="editDailyAgenda__addActivityModal__name"
            />
            <InputWithLabel
              inputImg={activityDescriptionIcon}
              onChange={handleActivityDescriptionChange}
              placeholder={t(TRANSLATIONS.WRITE_DESCRIPTION)}
              value={newActivityObject.description}
              labelClassName="search-header"
              type="text"
              maxLength={1000}
              inputClassName="search-input"
              containerClassName="editDailyAgenda__addActivityModal__description"
            />
            <div className="editDailyAgenda__addActivityModal__pillarContainer">
              <LabelledInputWithImage
                autoCompleteIcon={ImageLinks.listHorizontal}
                inputStyle={{ width: '100vw', borderColor: '#DEDEDE', padding: '4px', fontWeight: 300, borderRadius: '5px', paddingLeft: '45px' }}
                onChange={e => onChangePillar(e)}
                placeholder={t(TRANSLATIONS.SELECT_PILLAR)}
                dropdownOptions={(pillarsData as any || []).map(pillar => ({
                  value: pillar.id,
                  label: pillar.name,
                }))}
                type="autocomplete-dropdown"
                labelStyle={{ width: '220px' }}
                borderColor={'#DEDEDE'}
                borderHoverColor={'#4472C4'}
                selectedValue={newActivityObject.pillar}
              />
            </div>
            <p className="editDailyAgenda__addActivityModal__footerDescription">{t(TRANSLATIONS.THIS_ACTIVITY_WILL_ONLY_DISPLAY_ONE_QUESTION_IN_THE_MOBILE_APP_WHICH_WILL_BE_TO_REQUEST_PHOTOGRAPHIC_EVIDENCE)}</p>
            <div className="editDailyAgenda__addActivityModal__buttonContainer">
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                className="editDailyAgenda__addActivityModal__buttonContainer__cancelButton"
                onClick={onToggleNewActivityModal} />
              <BasicButton
                disabled={isNewActivityCreateButtonDisabled}
                text={isEditingActivity ? t(TRANSLATIONS.SAVE_CHANGES) : t(TRANSLATIONS.CREATE)}
                className={`${isNewActivityCreateButtonDisabled ? 'editDailyAgenda__addActivityModal__buttonContainer__createButtonWithoutHover' : 'editDailyAgenda__addActivityModal__buttonContainer__createButton'}`}
                onClick={isEditingActivity ? onSaveEditedActivity : onCreateNewActivity} />
            </div>
          </div>
        </Modal>
      )}
      <div className="editDailyAgenda__header">
        <div className="editDailyAgenda__header__info">
          <div role='button' onClick={onPressBack} className="editDailyAgenda__header__info__goBack">
            <img
              src={ImageLinks.arrowLeftV1}
              className="editDailyAgenda__header__info__goBack__icon"
              alt="back"
            />
          </div>
          <div className="editDailyAgenda__header__info__content">
            <p className="editDailyAgenda__header__info__content__title">{t(TRANSLATIONS.EDITING_AGENDA_PROGRAMMING)}</p>
            <p className="editDailyAgenda__header__info__content__subtitle">{`${t(TRANSLATIONS.PROGRAMMING_OF)} ${selectedBusinessUnit.name || ''} ${t(TRANSLATIONS.TO_EDIT_ANOTHER_ONE_GO_BACK_AND_SELECT_A_DIFFERENT_BU)}`}</p>
          </div>
        </div>
        <BasicButton
          text={t(TRANSLATIONS.SAVE_CHANGES)}
          className="editDailyAgenda__header__edit_button"
          onClick={onPressSaveChanges}
          disabled={fetchStatus === FETCH_STATUS.PENDING || fetchStatus === FETCH_STATUS.NO_ACTIVE_REQUEST}
        />
      </div>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="editDailyAgenda__dnd">
            {Object.keys(dndChecklistList)?.map((column: any) => {
              const isLeftPanel = column === DndCards.CARD_1;

              if (isLeftPanel) {
                return (
                  <div className="editDailyAgenda__dnd__dndCard1">
                    <div className="editDailyAgenda__dnd__dndCard1__cards">
                      <div className="editDailyAgenda__dnd__dndCard1__cards__searchWrap">
                        <InputWithLabel
                          inputRef={inputRef}
                          inputImg={checklistSearch}
                          onChange={handleSearch}
                          placeholder={t(TRANSLATIONS.SEARCH)}
                          value={search}
                          labelClassName="search-header"
                          type="text"
                          maxLength={1000}
                          inputClassName="search-input"
                          containerClassName="editDailyAgenda__dnd__dndCard1__cards__searchWrap__search"
                        />
                        <div role='button' onClick={onAddActivity} className="editDailyAgenda__dnd__dndCard1__cards__searchWrap__newTaskLabel">+ {t(TRANSLATIONS.NEW)}</div>
                      </div>
                      <Droppable droppableId={column}>
                        {provided => (
                          <div ref={provided.innerRef} className="editDailyAgenda__dnd__dndCard1__cards__wrap">
                            {(dndChecklistList?.[column]?.length) ? (
                              <div className="editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer">
                                {dndChecklistList?.[column]?.map(
                                  (cardData: any, index1: number) => {
                                    return (
                                      <Draggable
                                        key={`${cardData?.id}-${cardData?.slug}`}
                                        draggableId={cardData?.id}
                                        index={index1}
                                      >
                                        {providedV1 => (
                                          <div
                                            className="editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer__card"
                                            role="presentation"
                                            ref={providedV1.innerRef}
                                            {...providedV1.draggableProps}
                                            {...providedV1.dragHandleProps}
                                          >
                                            <p className='editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer__card__title'>
                                              {`${cardData?.type || ''} - ${cardData?.label}`}
                                            </p>
                                            {/* <div role="button" onClick={() => onEditActivity(cardData)} className="editDailyAgenda__dnd__dndCard1__cards__wrap__cardContainer__card__editIcon">
                                              <img
                                                src={ImageLinks.editGrey}
                                                alt="deletePosition"
                                              />
                                            </div> */}
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                              </div>
                            ) : (
                              <div className="editDailyAgenda__dnd__dndCard1__cards__wrap__noDataWrap">
                                <p className="editDailyAgenda__dnd__dndCard1__cards__wrap__noDataWrap__title">{t(TRANSLATIONS.THRE_ARE_NO_TASKS_CREATED_YET_TO_CREATE_A_NEW_ONE_PRESS_NEW)}</p>
                                <p className="editDailyAgenda__dnd__dndCard1__cards__wrap__noDataWrap__subTitle">{t(TRANSLATIONS.THE_TASKS_OF_THE_DPO_MODULES_ARE_ALREADY_CONNECTD_TO_THE_DAILY_AGENDA_THEY_DO_NOT_NEED_TO_BE_CREATED)}</p>
                              </div>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                );
              }
              {
                return fetchStatus === FETCH_STATUS.PENDING ? (
                  <div className="dailyAgenda__loader">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="editDailyAgenda__dnd__dndCard2">
                    <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap">
                      <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap">
                        <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap__container">
                          <ChartDropdown
                            onChange={handleAddPosition}
                            selectedDropDownItem={selectedPosition}
                            dropDownItems={positions}
                            isDisabled={positions?.length === 0}
                            dropDownOpen={openPositionsDropdown}
                            setDropDownOpen={setOpenPositionsDropdown}
                            customLabel={`+ ${t(TRANSLATIONS.ADD_POSITION)}`}
                            customLabelClassName={positions?.length ? 'editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap__container__label' : 'editDailyAgenda__dnd__dndCard2__positionCardsWrap__addPositionWrap__container__disabledLabel'}
                          />
                        </div>
                      </div>
                      {selectedPositions?.length ? (
                        selectedPositions.map((position, index2) => (
                          <div key={index2} className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards">
                            <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header">
                              <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header__label">{position}</p>
                              <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header__deletePostionWrap">
                                <img src={ImageLinks.deleteGreyV1} alt="delete position" />
                                <div
                                  role="button"
                                  onClick={() => onDeletePosition(position)}
                                  className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__header__deletePostionWrap__label"
                                >
                                  {t(TRANSLATIONS.DELETE_POSITION)}
                                </div>
                              </div>
                            </div>
                            <Droppable droppableId={`${column}-${position}`}>
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap"
                                >
                                  {dndChecklistList[DndCards.CARD_2]?.filter(item => item?.position === position)?.length ? (
                                    <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer">
                                      {dndChecklistList[DndCards.CARD_2]
                                        ?.filter(item => item?.position === position)
                                        ?.map((cardData, index1) => (
                                          <Draggable
                                            key={cardData?.id}
                                            draggableId={cardData?.id}
                                            index={index1}
                                          >
                                            {providedV1 => (
                                              <div
                                                className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card"
                                                role="presentation"
                                                ref={providedV1.innerRef}
                                                {...providedV1.draggableProps}
                                                {...providedV1.dragHandleProps}
                                              >
                                                <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card__title">
                                                  {`${cardData?.type} - ${cardData?.label}`}
                                                </p>
                                                <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card__positionWrap">
                                                  <ChartDropdown
                                                    onChange={data =>
                                                      handleFrequencyChange(data, index1, position)
                                                    }
                                                    selectedDropDownItem={cardData?.frequency}
                                                    dropDownItems={frequencyList}
                                                    dropDownOpen={
                                                      openDropdownIndex === `${index1}-${position}`
                                                    }
                                                    setDropDownOpen={() =>
                                                      setOpenDropdownIndex(
                                                        openDropdownIndex === `${index1}-${position}`
                                                          ? null
                                                          : `${index1}-${position}`,
                                                      )
                                                    }
                                                    labelClassName="label-default"
                                                  />
                                                  <div
                                                    role="button"
                                                    onClick={() =>
                                                      onDeleteActivity(cardData?.id, position)
                                                    }
                                                    className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__cardContainer__card__positionWrap__deleteIcon"
                                                  >
                                                    <img
                                                      src={ImageLinks.deleteGreyV2}
                                                      alt="deletePosition"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                    </div>
                                  ) : (
                                    <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__noDataWrap">
                                      <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__cards__wrap__noDataWrap__title">
                                        {t(TRANSLATIONS.DRAG_A_CREATED_TASK_TO_START)}
                                      </p>
                                    </div>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        ))
                      ) : (
                        <div className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__noPositionsAddedWrap">
                          <p className="editDailyAgenda__dnd__dndCard2__positionCardsWrap__noPositionsAddedWrap__label">
                            {t(
                              TRANSLATIONS.THERE_ARE_NO_POSITIONS_CREATED_YET_TO_ADD_A_NEW_ONE_PRESS_ADD_POSITION,
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );}

            })}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default EditDailyAgenda;
